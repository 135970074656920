import React, { useEffect, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";

import { ConfigurationService, OpenAPI } from "./client";
import { AppRouter } from "./AppRouter";
import "./App.css";
import { SnackbarProvider } from "notistack";

const SecureApp = ({ audience, scope }) => {
    const { user, getAccessTokenSilently } = useAuth0();
    // this access token is not used for anything, since our API client reads it from OpenAPI.TOKEN global variable.
    // however, it's used to avoid rendering <AppRouter /> where all the application logic is and needs the access token.
    const [accessToken, setAccessToken] = useState();

    useEffect(() => {
        const getAccessToken = async () => {
            try {
                const accessTkn = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: audience,
                        scope: scope,
                    },
                });

                OpenAPI.TOKEN = accessTkn;
                setAccessToken(accessTkn);
            } catch (e) {
                OpenAPI.TOKEN = null;
                setAccessToken(null);

                console.log(e);
            }
        };

        getAccessToken();
    }, [getAccessTokenSilently, user?.sub]);

    // avoid rendering <AppRouter /> without an accessToken, since it needs it.
    if (!accessToken) {
        return null;
    }

    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
            <AppRouter />
        </SnackbarProvider>
    );
};

SecureApp.propTypes = {
    audience: PropTypes.string,
    scope: PropTypes.string,
};

const EnforceSecureApp = (params) => {
    const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();

    useEffect(() => {
        const loginIfNeeded = async () => {
            if (!isLoading && !isAuthenticated) {
                await loginWithRedirect();
            }
        };

        loginIfNeeded();
    }, [isLoading, isAuthenticated]);

    if (!isAuthenticated || isLoading || error) {
        return null;
    }

    return (
        <SecureApp {...params} />
    );
};

const App = () => {
    const [domain, setDomain] = useState();
    const [clientId, setClientId] = useState();
    const [audience, setAudience] = useState();
    const [scope, setScope] = useState();

    useEffect(() => {
        ConfigurationService.configurationGetAuth()
            .then((data) => {
                setDomain(data.domain);
                setClientId(data.client_id);
                setAudience(data.audience);
                setScope(data.scope);
            });
    }, []);

    if (!domain || !clientId || !audience) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: audience,
                scope: scope,
            }}
            useRefreshTokens
            useRefreshTokensFallback
        >
            <EnforceSecureApp audience={audience} scope={scope} />
        </Auth0Provider>
    );
};

export default App;
