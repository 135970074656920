"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VersionService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var VersionService = /** @class */ (function () {
    function VersionService() {
    }
    /**
     * Get
     * @returns string Successful Response
     * @throws ApiError
     */
    VersionService.versionGet = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/version',
        });
    };
    return VersionService;
}());
exports.VersionService = VersionService;
