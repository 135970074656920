import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import { AppContext } from "../../AppRouter";
import CollectorDetails from "./CollectorDetails";
import { isEmpty } from "lodash";

const EditCollector = () => {
    const { client, config, notify } = useContext(AppContext);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { collectorId } = useParams();

    function isValid(name) {
        return !isEmpty(name);
    }

    const editCollector = (name) => {
        setShouldValidate(true);
        if (collectorId && isValid(name)) {
            setLoading(true);
            client.collector.collectorUpdateCollector(collectorId, name)
                .then(() => {
                    setLoading(false);
                    navigate("/collector");
                })
                .catch((error) => {
                    setLoading(false);
                    notify.error(error, "collector.edit");
                });
        }
    };

    return (
        <CollectorDetails
            operation="edit"
            title={config.i18n.collector.edit}
            button={config.i18n.button.save}
            icon={<SaveIcon />}
            shouldValidate={shouldValidate}
            loading={loading}
            onClick={editCollector}
            hasTemplate={false}
        />
    );
};

export default EditCollector;
