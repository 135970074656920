import { OpenAPI } from "../client";

export function downloadReport(name, reportId, requestBody, onDownloadComplete, onDownloadError) {
    // the OpenAPI generated client does not handle binary responses
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "accept": "application/xlsx",
            "authorization": "Bearer " + OpenAPI.TOKEN,
        },
        mode: "cors",
        credentials: "include",
        body: JSON.stringify(requestBody),
    };
    fetch(`/api/report/${reportId}/download`, options)
        .then((response) => {
            if (response.ok) {
                return response.blob();
            } else {
                return Promise.reject(response);
            }
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = name + ".xlsx";

            document.body.appendChild(a);
            a.click();
            a.remove();
            onDownloadComplete();
        })
        .catch(() => {
            onDownloadError();
        });
}
