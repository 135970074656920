"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalysisLabelTypes = void 0;
var AnalysisLabelTypes;
(function (AnalysisLabelTypes) {
    AnalysisLabelTypes["INTEGER"] = "INTEGER";
    AnalysisLabelTypes["DECIMAL"] = "DECIMAL";
    AnalysisLabelTypes["AMOUNT"] = "AMOUNT";
    AnalysisLabelTypes["DATE"] = "DATE";
    AnalysisLabelTypes["PERCENTAGE"] = "PERCENTAGE";
})(AnalysisLabelTypes || (exports.AnalysisLabelTypes = AnalysisLabelTypes = {}));
