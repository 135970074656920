import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const CircularLoading = ({ height, label, size, color, flex }) => {
    return (
        <Box
            data-cy="loading"
            sx={{
                display: "flex", flexDirection: "column",
                justifyContent: "center", alignItems: "center", height: height,
                flex: flex,
            }}
        >
            <CircularProgress color={color} variant="indeterminate" size={size} />
            {
                label ? <Typography variant="caption" sx={{ mt: 3 }}>{label}</Typography> : null
            }
        </Box>
    );
};

CircularLoading.propTypes = {
    height: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    color: PropTypes.string,
    flex: PropTypes.any,
};

CircularLoading.defaultValues = {
    size: 40,
    color: "primary",
    flex: "auto",
};

export default CircularLoading;
