"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectorBlock = void 0;
var CollectorBlock;
(function (CollectorBlock) {
    CollectorBlock["REFERENCE"] = "REFERENCE";
    CollectorBlock["ACTUAL"] = "ACTUAL";
    CollectorBlock["DELTA"] = "DELTA";
})(CollectorBlock || (exports.CollectorBlock = CollectorBlock = {}));
