import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Tab, Tabs, Typography } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PriorityTable from "./PriorityTable";
import ValueProvenTable from "./ValueProvenTable";
import RefineMetric from "./RefineMetric";
import BasicsTable from "./BasicsTable";
import FullscreenLoading from "../../../../components/loading/FullscreenLoading";
import CustomParameterSelector from "../../../../components/CustomParameterSelector";
import DiscardDialog from "../../../../components/DiscardDialog";
import { AppContext } from "../../../../AppRouter";

const InitiativeDetailsDialog = ({ initiative, onClose, onSave }) => {
    const { client, config, notify } = useContext(AppContext);
    const { projectId } = useParams();

    const [originalInitiative, setOriginalInitiative] = useState();
    const [tempInternalInitiative, setTempInternalInitiative] = useState();
    const [showExitDialog, setShowExitDialog] = useState(false);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [tab, setTab] = useState("basics");

    const [loading, setLoading] = useState(false);

    const isValid = () => {
        if (tempInternalInitiative?.adjusted?.addressable_spend && !tempInternalInitiative?.comment?.addressable_spend) {
            notify.warn("procurement.project.initiative.addressable_spend.comment?");
            return false;
        } else if (tempInternalInitiative?.adjusted?.run_rate && !tempInternalInitiative?.comment?.run_rate) {
            notify.warn("procurement.project.initiative.run_rate.comment?");
            return false;
        } else if (tempInternalInitiative?.adjusted?.cost_to_achieve && !tempInternalInitiative?.comment?.cost_to_achieve) {
            notify.warn("procurement.project.initiative.cost_to_achieve.comment?");
            return false;
        } else if (tempInternalInitiative?.adjusted?.slope && !tempInternalInitiative?.comment?.slope) {
            notify.warn("procurement.project.initiative.slope.comment?");
            return false;
        } else if (tempInternalInitiative?.adjusted?.lead_time && !tempInternalInitiative?.comment?.lead_time) {
            notify.warn("procurement.project.initiative.lead_time.comment?");
            return false;
        } else if (tempInternalInitiative?.adjusted?.span && !tempInternalInitiative?.comment?.span) {
            notify.warn("procurement.project.initiative.span.comment");
            return false;
        }

        return true;
    };

    const previewInitiative = (initiative) => {
        setLoading(true);
        client.procurement.procurementPreviewProjectInitiative(projectId, initiative.id, initiative)
            .then((initiative) => {
                setTempInternalInitiative(initiative);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                notify.error(error, "procurement.project.initiative.preview");
            });
    };

    useEffect(() => {
        if (initiative) {
            setOriginalInitiative(initiative);
            setTempInternalInitiative(initiative);
        } else {
            setOriginalInitiative(null);
            setTempInternalInitiative(null);
        }

        setTab("basics");
    }, [initiative]);

    if (!tempInternalInitiative || !initiative) {
        return null;
    }

    const CustomParameterPaper = () =>
        (
            <Paper sx={{ p: 2, mb: 2 }}>
                <Typography variant="h6" align="center" sx={{ mb: 3 }}>
                    {config.i18n.custom_parameters.title}
                </Typography>
                <Box sx={{ display: "flex", p: 0.5 }}>
                    <CustomParameterSelector
                        definition={tempInternalInitiative.custom_parameters.filter(el => el.type === "CUSTOM")}
                        values={{
                            project_id: projectId,
                            initiative_id: initiative?.id,
                            ...tempInternalInitiative?.custom_parameters_values,
                        }}
                        onCustomParameterUpdate={(values) => {
                            previewInitiative({
                                ...tempInternalInitiative,
                                custom_parameters_values: values,
                            });
                        }}
                        sx={{ m: 0.5 }}
                    />
                </Box>
            </Paper>
        );

    return (
        <>
            <FullscreenLoading
                loading={loading}
                label={config.i18n.procurement.identify.loading}
            />
            <DiscardDialog
                open={showExitDialog}
                onDiscard={() => {
                    setShowExitDialog(false);
                    onClose();
                    setTempInternalInitiative(originalInitiative);
                }}
                onCancel={() => setShowExitDialog(false)}
            />
            <Dialog
                open={!!tempInternalInitiative}
                onClose={onClose}
                fullWidth
                maxWidth="xl"
                disableEscapeKeyDown
                PaperProps={{
                    sx: {
                        minHeight: 885,
                    },
                }}
            >
                <DialogTitle>
                    <Box>
                        <Typography variant="h4">{tempInternalInitiative.name}</Typography>
                        <Typography variant="caption">{tempInternalInitiative.description}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={tab}
                                onChange={(event, newValue) => {
                                    setTab(newValue);
                                }}
                            >
                                <Tab value="basics" label={config.i18n.procurement.prioritize.basics_tab} />
                                <Tab value="drivers" label={config.i18n.procurement.prioritize.drivers_tab} />
                                {
                                    tempInternalInitiative.analyses.map(analysis =>
                                        <Tab key={"tab-" + analysis.id} value={analysis.id} label={analysis.label} />,
                                    )
                                }
                            </Tabs>
                        </Box>

                        <TabPanel value="basics">
                            <Paper component="form" sx={{ p: 2 }}>
                                <BasicsTable
                                    initiative={tempInternalInitiative}
                                    onInitiativeValuesUpdate={initiative => setTempInternalInitiative(initiative)}
                                />
                            </Paper>
                            <Paper sx={{ p: 2, mt: 2 }}>
                                <PriorityTable
                                    initiative={tempInternalInitiative}
                                    setInitiative={initiative => setTempInternalInitiative(initiative)}
                                />
                            </Paper>
                        </TabPanel>
                        <TabPanel value="drivers">
                            <CustomParameterPaper />
                            <Paper sx={{ p: 2 }}>
                                <ValueProvenTable
                                    initiative={tempInternalInitiative}
                                    shouldValidate={shouldValidate}
                                    onCommentUpdate={initiative => setTempInternalInitiative(initiative)}
                                    onInitiativeValuesUpdate={initiative => previewInitiative(initiative)}
                                    onBaselineRefineClick={() => setTab("baseline")}
                                    onRunRateRefineClick={() => setTab("run_rate")}
                                />
                            </Paper>
                            <Paper sx={{ p: 2, mt: 2 }}>
                                <PriorityTable
                                    initiative={tempInternalInitiative}
                                    setInitiative={initiative => setTempInternalInitiative(initiative)}
                                />
                            </Paper>
                        </TabPanel>
                        {
                            originalInitiative.analyses.map(analysis =>
                                (
                                    <TabPanel key={"tabpanel-" + analysis.id} value={analysis.id}>
                                        <CustomParameterPaper />
                                        <RefineMetric
                                            externalLoading={loading}
                                            analysisId={analysis.id}
                                            customParametersValues={{
                                                project_id: projectId,
                                                initiative_id: tempInternalInitiative?.id,
                                                label: analysis.label,
                                                ...tempInternalInitiative?.custom_parameters_values,
                                            }}
                                        />
                                    </TabPanel>
                                ),
                            )
                        }
                    </TabContext>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setTempInternalInitiative({
                            ...originalInitiative,
                            custom_parameters_values: {},
                            adjusted: null,
                            comment: {},
                        });
                    }}
                    >
                        {config.i18n.custom_parameters.reset}
                    </Button>
                    <Button onClick={() => {
                        if (originalInitiative == tempInternalInitiative) {
                            onClose();
                        } else {
                            setShowExitDialog(true);
                        }
                    }}
                    >
                        {config.i18n.button.cancel}
                    </Button>
                    <Button
                        disabled={loading}
                        onClick={() => {
                            if (!isValid()) {
                                setShouldValidate(true);
                                return;
                            }

                            onSave(tempInternalInitiative);
                            onClose();
                        }}
                    >
                        {config.i18n.button.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

InitiativeDetailsDialog.propTypes = {
    initiative: PropTypes.object,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
};

InitiativeDetailsDialog.defaultProps = {
    onClose: () => {
    },
    onSave: () => {
    },
};

export default InitiativeDetailsDialog;
