import React, { useContext, useEffect, useState } from "react";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import PeopleIcon from "@mui/icons-material/People";
import StorefrontIcon from "@mui/icons-material/Storefront";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import VerifiedIcon from "@mui/icons-material/Verified";

import { Avatar, Button, Dialog, DialogContent, DialogTitle, Grid, Link, Skeleton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { hasValue } from "../util/util";
import { AppContext } from "../AppRouter";

const TryThis = () => {
    const { client, notify, config } = useContext(AppContext);
    const [topics, setTopics] = useState();
    const [dialogContent, setDialogContent] = useState();

    useEffect(() => {
        client.tryThis.tryThisGetTryThis()
            .then((data) => {
                setTopics(data);
            })
            .catch((error) => {
                notify.error(error, "analysis.run");
            });
    }, []);

    function getTopicName(topic) {
        return config.i18n.topics[topic];
    }

    function getTopicIcon(topic) {
        const styling = {
            color: "grey",
            width: 32,
            height: 32,
        };

        switch (topic) {
            case "REVENUE":
                return <AttachMoneyIcon sx={styling} />;
            case "COST":
                return <ShoppingCartIcon sx={styling} />;
            case "CLIENTS":
                return <PeopleIcon sx={styling} />;
            case "SUPPLIERS":
                return <StorefrontIcon sx={styling} />;
            case "MARGIN":
                return <TrendingUpIcon sx={styling} />;
            case "PLANNING_AND_CONTROL":
                return <EngineeringIcon sx={styling} />;
            case "PROCUREMENT":
                return <VerifiedIcon sx={styling} />;
            case "BALANCE_SHEET":
                return <DocumentScannerIcon sx={styling} />;
        }
    }

    const RoundGradientCircle = styled("div")(({ theme }) => ({
        width: 80,
        height: 80,
        border: "double 5px transparent",
        borderRadius: 40,
        backgroundImage: `linear-gradient(white, white), radial-gradient(circle at top left, lightgrey, grey, ${theme.palette.primary.main})`,
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
        boxShadow: theme.shadows[9],
    }));

    function createButton(id, title, avatar, content) {
        return (
            <Grid item key={id} xs={6} sm={5} md={4} lg={3} display="flex" flexDirection="column" alignItems="stretch" justifyContent="flex-start">
                <Button
                    size="large"
                    data-cy={`try_this-${id}`}
                    sx={{ borderRadius: "8px", display: "flex", flexDirection: "column", py: 2 }}
                    onClick={() => setDialogContent({ title: title, avatar: avatar, content: content })}
                    disabled={Array.isArray(content) && content.every(item => !hasValue(item))}
                >
                    <RoundGradientCircle sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        {avatar}
                    </RoundGradientCircle>
                    <Typography marginTop={2} textTransform="none">
                        {title}
                    </Typography>
                </Button>
            </Grid>
        );
    }

    let buttons = [];
    if (!topics) {
        buttons = [...Array(12)].map((x, index) => {
            createButton(
                index,
                <Skeleton animation="wave" />,
                <Skeleton variant="circular" animation="wave"><Avatar /></Skeleton>,
                <Typography noWrap variant="body1">
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </Typography>,
            );
        });
    } else {
        buttons = topics.map(topic =>
            createButton(
                topic.topic_id,
                getTopicName(topic.topic_id),
                getTopicIcon(topic.topic_id),
                topic.links.map((analysisLink, index) => {
                    const link = analysisLink.link;
                    if (!link) {
                        return null;
                    }
                    const question = analysisLink.question;
                    return (
                        <Tooltip
                            followCursor
                            placement="bottom-start"
                            key={analysisLink.analysis_id}
                            title={question}
                        >
                            <Typography noWrap variant="body1">
                                <Link
                                    data-tooltip={question}
                                    data-cy={`try_this-${topic.topic_id}-${index}`}
                                    href={link}
                                >
                                    {question}
                                </Link>
                            </Typography>
                        </Tooltip>
                    );
                }),
            ),
        );
    }

    return (
        <center>
            <Grid container width="50%" justifyContent="center" data-cy="try_this" spacing={1}>
                {buttons}
            </Grid>
            {dialogContent
                ? (
                    <Dialog
                        open={!!dialogContent}
                        onClose={() => setDialogContent(null)}
                        data-cy="try_this-dialog"
                    >
                        <DialogTitle
                            sx={{ variant: "h6", color: "grey", display: "flex", alignItems: "center", gap: 1.5 }}
                        >
                            {dialogContent?.avatar}
                            {" "}
                            {dialogContent?.title}
                        </DialogTitle>
                        <DialogContent>
                            {dialogContent?.content}
                        </DialogContent>
                    </Dialog>
                    )
                : null}
        </center>
    );
};

export default TryThis;
