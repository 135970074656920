import { getAxisTooltip, getAxisV, getBase, getLegend, getYSeries } from "./common";
import {
    getAxisV as getTimeAxisV,
    getYSeries as getTimeYSeries,
    getAxisTooltip as getTimeAxisTooltip,
} from "./timeline/common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    let chartOptions = {
        ...getBase(title, baseFontSize),
        ...getLegend(data, config, baseFontSize),
    };

    // Stacked column can have both time axis and categorical axis
    if (data.label_type.x === "DATE") {
        chartOptions = {
            ...chartOptions,
            ...getTimeAxisTooltip(data, config, baseFontSize),
            ...getTimeAxisV(data, config, baseFontSize),
            ...getTimeYSeries(data, "bar", baseFontSize, config),
        };

        // Min & Max labels are not formatted to the appropriate time format, so they are not shown
        chartOptions.xAxis.axisLabel.showMaxLabel = false;
        chartOptions.xAxis.axisLabel.showMinLabel = false;
    } else {
        chartOptions = {
            ...chartOptions,
            ...getAxisTooltip(data, config, baseFontSize),
            ...getAxisV(data, config, baseFontSize),
            ...getYSeries(data, "bar", baseFontSize, config),
        };
    }
    return chartOptions;
};
