import {
    defaultValueLabelSetting,
    getAxisH,
    getAxisTooltip,
    getBase,
    getFormattedValue,
    getYSeriesData,
    overrideNegativeLabelPosition,
} from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        ...getBase(title, baseFontSize),
        ...getAxisH(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        series: getYSeriesData(data).map(series =>
            ({
                type: "bar",
                data: overrideNegativeLabelPosition(series, "left"),
                label: defaultValueLabelSetting("right", baseFontSize, config,
                    value => getFormattedValue(value.data.value, "y", data, config)),
            })),
    };
};
