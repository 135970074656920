import React, { useContext } from "react";
import { Button, Typography } from "@mui/material";
import AdvisorContainer from "./AdvisorContainer";
import { Link as RouterLink } from "react-router-dom";
import { AppContext } from "../AppRouter";

const ErrorContainer = () => {
    const { config } = useContext(AppContext);

    return (
        <AdvisorContainer
            title={config.i18n.error_page.title}
            subtitle={config.i18n.error_page.subtitle}
        >
            <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to="/"
                sx={{ alignSelf: "start" }}
            >
                <Typography variant="h6">
                    {config.i18n.error_page.button}
                </Typography>
            </Button>
        </AdvisorContainer>
    );
};

export default ErrorContainer;
