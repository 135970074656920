import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext } from "react";
import PropTypes from "prop-types";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { AppContext } from "../../AppRouter";

const DeleteIconButton = ({ size, color, variant, disabled, onClick }) => {
    const { config } = useContext(AppContext);

    return (
        <Tooltip title={config.i18n.button.delete}>
            <IconButton
                // grey is not a supported color.
                // not defining it, defaults to icon color, which is grey.
                color={color === "grey" ? undefined : color}
                size={size}
                disabled={disabled}
                onClick={onClick}
            >
                {
                    variant === "outlined"
                        ? <DeleteOutlineIcon />
                        : <DeleteIcon />
                }
            </IconButton>
        </Tooltip>
    );
};

DeleteIconButton.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

DeleteIconButton.defaultProps = {
    size: "small",
    color: "error",
    variant: "regular",
    onClick: () => {
    },
};

export default DeleteIconButton;
