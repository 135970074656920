"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Module = void 0;
var Module;
(function (Module) {
    Module["ASK_ME"] = "ASK_ME";
    Module["OPTIMIZE"] = "OPTIMIZE";
    Module["EXPLORE"] = "EXPLORE";
    Module["REPORT"] = "REPORT";
    Module["FORECAST"] = "FORECAST";
    Module["USER_GUIDE"] = "USER_GUIDE";
    Module["COLLECTOR"] = "COLLECTOR";
})(Module || (exports.Module = Module = {}));
