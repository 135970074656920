"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService() {
    }
    /**
     * Get Profile
     * @returns User The authenticated user profile.
     * @throws ApiError
     */
    AuthenticationService.authenticationGetProfile = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/auth/profile',
        });
    };
    return AuthenticationService;
}());
exports.AuthenticationService = AuthenticationService;
