"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var UserService = /** @class */ (function () {
    function UserService() {
    }
    /**
     * List By Type
     * @param userType
     * @param page
     * @param limit
     * @returns PaginatedResponse_User_ The paginated users of the given type.
     * @throws ApiError
     */
    UserService.userListByType = function (userType, page, limit) {
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user',
            query: {
                'user_type': userType,
                'page': page,
                'limit': limit,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get Users
     * @param requestBody
     * @returns User The requested users.
     * @throws ApiError
     */
    UserService.userGetUsers = function (requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get
     * @param userId
     * @returns User The requested user.
     * @throws ApiError
     */
    UserService.userGet = function (userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    return UserService;
}());
exports.UserService = UserService;
