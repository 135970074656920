"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomParameterType = void 0;
var CustomParameterType;
(function (CustomParameterType) {
    CustomParameterType["SELECT"] = "SELECT";
    CustomParameterType["NUMBER"] = "NUMBER";
    CustomParameterType["AMOUNT"] = "AMOUNT";
    CustomParameterType["PERCENTAGE"] = "PERCENTAGE";
    CustomParameterType["TEXT"] = "TEXT";
    CustomParameterType["DATE"] = "DATE";
})(CustomParameterType || (exports.CustomParameterType = CustomParameterType = {}));
