import React, { useContext } from "react";
import { AppBar, Avatar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import HdrAuto from "@mui/icons-material/HdrAuto";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PropTypes from "prop-types";

import CircularLoading from "../components/loading/CircularLoading";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AppContext } from "../AppRouter";
import { stringAvatar } from "../util/avatar";

const Header = ({ isDrawerPinned, onPinDrawerClick }) => {
    const { user } = useContext(AppContext);
    const { logout } = useAuth0();

    return (
        <AppBar position="sticky" sx={{ zIndex: "1201" }}>
            <Toolbar datacy="header">
                <IconButton
                    data-cy="menu_drawer"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onPinDrawerClick}
                    sx={{ mr: 2 }}
                >

                    {isDrawerPinned ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
                <IconButton
                    edge="start"
                    color="inherit"
                    component={Link}
                    to="/"
                >
                    <HdrAuto fontSize="large" />
                </IconButton>
                <Typography variant="h6" component="div" noWrap sx={{ flexGrow: "1" }}>
                    Advisor
                </Typography>
                {
                    user
                        ? (
                            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                { user?.picture ? (
                                    <Avatar
                                        alt={user.name}
                                        src={user.picture}
                                        sx={{
                                            width: 32, height: 32,
                                        }}
                                    />
                                ) : (
                                    <Avatar
                                        {...stringAvatar(user.name)}
                                        sx={{
                                            width: 32, height: 32,
                                        }}
                                    />
                                ) }
                                <div>{user.name}</div>
                                <IconButton
                                    datacy="logout"
                                    color="inherit"
                                    onClick={() => logout({
                                        logoutParams: { returnTo: window.location.origin },
                                    })}
                                >
                                    <Logout />
                                </IconButton>
                            </Box>
                            )
                        : null
                }
                {
                    !user && (
                        <CircularLoading color="inherit" size={25} />
                    )
                }
            </Toolbar>
        </AppBar>
    );
};

Header.propTypes = {
    isDrawerPinned: PropTypes.bool,
    onPinDrawerClick: PropTypes.func,
};

export default Header;
