"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementInitiativeTopic = void 0;
var ProcurementInitiativeTopic;
(function (ProcurementInitiativeTopic) {
    ProcurementInitiativeTopic["PE_PROCESS_EFFICIENCY"] = "PE_PROCESS_EFFICIENCY";
    ProcurementInitiativeTopic["PE_PROCESS_CONSISTENCY"] = "PE_PROCESS_CONSISTENCY";
    ProcurementInitiativeTopic["PE_MAVERICK_SPEND"] = "PE_MAVERICK_SPEND";
    ProcurementInitiativeTopic["PE_BAD_ACTORS"] = "PE_BAD_ACTORS";
    ProcurementInitiativeTopic["PE_CONTRACT_PORTFOLIO"] = "PE_CONTRACT_PORTFOLIO";
    ProcurementInitiativeTopic["PE_CONTRACT_LEAKAGE"] = "PE_CONTRACT_LEAKAGE";
    ProcurementInitiativeTopic["PE_ORG_OPTIMIZATION"] = "PE_ORG_OPTIMIZATION";
    ProcurementInitiativeTopic["TP_RE_SPEC"] = "TP_RE_SPEC";
    ProcurementInitiativeTopic["TP_SIMPLIFY_CATALOGUE"] = "TP_SIMPLIFY_CATALOGUE";
    ProcurementInitiativeTopic["TP_MAKE_OR_BUY"] = "TP_MAKE_OR_BUY";
    ProcurementInitiativeTopic["TP_STANDARDIZE_REQUIREMENTS"] = "TP_STANDARDIZE_REQUIREMENTS";
    ProcurementInitiativeTopic["DL_ELIMINATE_SPEND"] = "DL_ELIMINATE_SPEND";
    ProcurementInitiativeTopic["DL_REDUCE_VOLUME"] = "DL_REDUCE_VOLUME";
    ProcurementInitiativeTopic["DL_REDUCE_PURCHASE_FREQUENCY_DURATION"] = "DL_REDUCE_PURCHASE_FREQUENCY_DURATION";
    ProcurementInitiativeTopic["DL_PURCHASE_BUNDLING"] = "DL_PURCHASE_BUNDLING";
    ProcurementInitiativeTopic["CP_VENDOR_PORTFOLIO_OPTIMIZATION"] = "CP_VENDOR_PORTFOLIO_OPTIMIZATION";
    ProcurementInitiativeTopic["CP_BEST_COST_COUNTRY"] = "CP_BEST_COST_COUNTRY";
    ProcurementInitiativeTopic["CP_VENDOR_MANAGEMENT"] = "CP_VENDOR_MANAGEMENT";
})(ProcurementInitiativeTopic || (exports.ProcurementInitiativeTopic = ProcurementInitiativeTopic = {}));
