"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParameterType = void 0;
var ParameterType;
(function (ParameterType) {
    ParameterType["LIMIT"] = "LIMIT";
    ParameterType["HIDE_TAIL"] = "HIDE_TAIL";
    ParameterType["IGNORE_NULL"] = "IGNORE_NULL";
    ParameterType["HIDE_INSIGHTS"] = "HIDE_INSIGHTS";
    ParameterType["DIMENSION"] = "DIMENSION";
    ParameterType["DATE"] = "DATE";
    ParameterType["DATE_AGG"] = "DATE_AGG";
    ParameterType["ANNUALIZE"] = "ANNUALIZE";
    ParameterType["CUMULATIVE"] = "CUMULATIVE";
    ParameterType["FOREX_DATE"] = "FOREX_DATE";
    ParameterType["STAT_LINES"] = "STAT_LINES";
    ParameterType["SCENARIO"] = "SCENARIO";
    ParameterType["CUTOFF_DATE"] = "CUTOFF_DATE";
})(ParameterType || (exports.ParameterType = ParameterType = {}));
