import { defaultValueLabelSetting, getAxisTooltip, getAxisV, getBase, getFormattedValue, getYSeriesData } from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        series: getYSeriesData(data).map(series =>
            ({
                type: "line",
                data: series,
                areaStyle: {},
                label: defaultValueLabelSetting("top", baseFontSize, config,
                    value => getFormattedValue(value.data.value, "y", data, config)),
            })),
    };
};
