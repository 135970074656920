import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Markdown from "markdown-to-jsx";

const SelectField = ({ size, value, label, disabled, possibleValues, metadata, onChange, sx, dataCyProp, renderValue, multiple, useMarkdown, required, error, helperText }) => {
    return (
        <FormControl sx={sx} size={size} fullWidth error={error} required={required}>
            <InputLabel id="value-select-label" disabled={disabled}>{label}</InputLabel>
            <Select
                data-cy={dataCyProp}
                labelId="value-select-label"
                id="value-select"
                multiple={multiple}
                label={label}
                value={value}
                disabled={disabled}
                onChange={event => onChange(event.target.value)}
                renderValue={renderValue}
                error={error}
                MenuProps={{ disableScrollLock: true }}
            >
                {
                    possibleValues?.map((option) => {
                        const optionKey = option?.id || option?.name || option;
                        const optionValue = option?.name || metadata[option] || option;

                        return (
                            <MenuItem
                                data-cy={"select-option-" + optionKey}
                                key={optionKey}
                                value={option}
                                disabled={disabled}
                            >
                                {useMarkdown ? <Markdown>{optionValue}</Markdown> : optionValue}
                            </MenuItem>
                        );
                    })
                }
            </Select>
            {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

SelectField.propTypes = {
    value: PropTypes.any,
    size: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    possibleValues: PropTypes.array,
    metadata: PropTypes.object,
    onChange: PropTypes.func,
    sx: PropTypes.object,
    dataCyProp: PropTypes.string,
    renderValue: PropTypes.any,
    multiple: PropTypes.bool,
    useMarkdown: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
};

SelectField.defaultProps = {
    size: "small",
    metadata: {},
    onChange: () => {
    },
    sx: {},
    dataCyProp: "selector",
    multiple: false,
    useMarkdown: false,
};

export default SelectField;
