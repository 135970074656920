import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReportDetails from "./ReportDetails";
import { convertToISODate } from "../../util/util";
import SaveIcon from "@mui/icons-material/Save";
import { AppContext } from "../../AppRouter";
import { isForecastingEnabled } from "../../util/feature_flag_util";

const CloneReport = () => {
    const { client, config, notify } = useContext(AppContext);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { reportId } = useParams();

    const isValid = (name, template, startDate, endDate) => {
        return !!(name && template && startDate && endDate);
    };

    const cloneReport = (name, template, scenario, startDate, endDate, cutoff_date, multiplier, hideEmptyRows, hideInitialMonths, filtersInDimensions, filtersInParameters,
        filtersOutDimensions, filtersOutParameters) => {
        setShouldValidate(true);
        if (reportId && isValid(name, template, startDate, endDate)) {
            setLoading(true);
            client.report.reportCloneReport(reportId,
                {
                    name: name,
                    template: template.id,
                    scenario: scenario?.id, // scenario is optional
                    start_date: convertToISODate(startDate),
                    end_date: convertToISODate(endDate),
                    cutoff_date: isForecastingEnabled(config) ? convertToISODate(cutoff_date) : null,
                    multiplier: multiplier,
                    hide_empty_rows: hideEmptyRows,
                    hide_initial_months: hideInitialMonths,
                    ui_filters: {
                        dimensions_filters_in: filtersInDimensions,
                        parameters_filters_in: filtersInParameters,
                        dimensions_filters_out: filtersOutDimensions,
                        parameters_filters_out: filtersOutParameters,
                    },
                })
                .then(() => {
                    setLoading(false);
                    navigate("/report");
                })
                .catch((error) => {
                    setLoading(false);
                    notify.error(error, "report.clone");
                });
        }
    };

    return (
        <ReportDetails
            operation="clone"
            title={config.i18n.report.clone}
            button={config.i18n.button.save}
            icon={<SaveIcon />}
            shouldValidate={shouldValidate}
            loading={loading}
            fetchReport
            onClick={cloneReport}
        />
    );
};

export default CloneReport;
