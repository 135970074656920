"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HealthCheckService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var HealthCheckService = /** @class */ (function () {
    function HealthCheckService() {
    }
    /**
     * Healthcheck
     * @returns boolean Advisor and database health check.
     * @throws ApiError
     */
    HealthCheckService.healthCheckHealthcheck = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/healthcheck',
        });
    };
    return HealthCheckService;
}());
exports.HealthCheckService = HealthCheckService;
