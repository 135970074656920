import React, { useContext } from "react";
import PropTypes from "prop-types";
import { isModuleEnabled } from "./feature_flag_util";
import { AppContext } from "../AppRouter";

export const Requires = ({ children, flag }) => {
    const { config } = useContext(AppContext);

    if (!isModuleEnabled(config, flag)) {
        return null;
    }

    return (
        <>
            {" "}
            {children}
            {" "}
        </>
    );
};

Requires.propTypes = {
    children: PropTypes.any,
    flag: PropTypes.string,
};
