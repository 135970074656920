import React, { useContext, useEffect, useState } from "react";
import AdvisorContainer from "../../layout/AdvisorContainer";
import dayjs from "dayjs";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
    formatYearMonth,
    getAllMonthsBetweenDates,
} from "../../util/formatter";
import { Alert, Box, Button, Chip, Typography, useTheme } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { computeOperator } from "../../util/math";
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../AppRouter";
import { isEmpty, isEqual } from "lodash";
import { hasValue } from "../../util/util";
import { getCellColor, getFontColor, isActualDate, isEditableCell, isForecastDate, isNormalUserEditing, isPowerUser } from "../../util/collector_util";
import { LoadingButton } from "@mui/lab";

const CollectorEntryItemsEdit = () => {
    const { client, config, notify } = useContext(AppContext);
    const theme = useTheme();

    const [entry, setEntry] = useState(null);
    const [collector, setCollector] = useState(null);
    const [dataActual, setDataActual] = useState(null);
    const [dataReference, setDataReference] = useState(null);
    const [dataDelta, setDataDelta] = useState(null);
    const [items, setItems] = useState(null);
    const [rows, setRows] = useState(null);
    const [changed, setChanged] = useState(false); // by default, the "save" button is disabled
    const [extEntriesNames, setExtEntriesNames] = useState(null);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const { collectorId, entryId } = useParams();
    const navigate = useNavigate();

    const formatter = (value, isPercentage) => {
        if (isPercentage) {
            return new Intl.NumberFormat(config.collector.locale, {
                style: "percent",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            });
        }

        return new Intl.NumberFormat(config.collector.locale, {
            currencySign: "accounting",
            currencyDisplay: "code",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    const getRowName = (cell) => {
        return {
            type: "text",
            text: cell?.name,
            nonEditable: true,
            style: {
                fontWeight: cell.text_style,
                paddingLeft: cell.indentation * 20 + 5,
                alignItems: "flex-end",
            },
        };
    };

    const getRowCellValue = (cell, permission, collectorEntry, collector, val, key, isPercentage, block) => {
        const isEditable = isEditableCell(permission, collectorEntry, key, collector.cutoff_date, block);

        return {
            type: "number",
            id: cell.id, // needed to match with items
            collector_entry_item_template_id: cell.collector_entry_item_template_id,
            column: key,
            block: block,
            isPercentage: isPercentage,
            nonEditable: !isEditable,
            format: formatter(val, isPercentage),
            style: {
                fontWeight: cell.text_style,
                background: getCellColor(isEditable, block, key, collector.cutoff_date),
                color: getFontColor(isEditable, val),
                alignItems: "flex-end",
            },
        };
    };

    const getOtherCell = (cell, block, column) => {
        return {
            id: cell.id,
            type: "number",
            block: block,
            column: column,
            isPercentage: cell.is_percentage,
            nonEditable: true,
            format: formatter(null, cell.is_percentage),
            total_aggregation: cell.total_aggregation,
            total_calculated_entries: cell.total_calculated_entries,
            style: {
                fontWeight: cell.text_style,
                color: "grey",
                alignItems: "flex-end",
            },
        };
    };

    const getRowCells = (cell, index, permissionActual, permissionForecast, items, collector, collectorEntry, block) => {
        return {
            rowId: index,
            cells: [
                getRowName(cell),
                ...Object.entries(cell?.values[block]).map(([key]) => {
                    const val = items.find(row => row.id === cell.id)?.values[key];

                    return getRowCellValue(cell, isActualDate(key, collector.cutoff_date) ? permissionActual : permissionForecast,
                        collectorEntry, collector, val, key, cell.is_percentage, block);
                }),
                getOtherCell(cell, block, config.i18n.collector.ytd),
                getOtherCell(cell, block, config.i18n.collector.ytg),
                getOtherCell(cell, block, config.i18n.collector.total),
            ],
        };
    };

    const handleCellEdit = (changes) => {
        let newItems = items;

        for (let i = 0; i < changes.length; i++) { // multiple cells can be changed at once, for example, when pasting multiple values
            const newCell = changes[i].newCell;

            newItems = newItems.map((row) => {
                if (row.id === newCell.id) {
                    if (isNaN(newCell.value)) {
                        newCell.value = null; // dataGrid component interprets the deletion of a value as becoming NaN
                    }
                    return {
                        ...row,
                        values: {
                            ...row.values,
                            [newCell.block]: {
                                ...row.values[newCell.block],
                                [newCell.column]: newCell.value,
                            },
                        },
                    };
                }
                return row;
            });
        }

        setItems(newItems);
        setChanged(true);
    };

    const saveCollectorEntry = () => {
        setIsSaving(true);
        const dataToUpdate = dataActual.concat(dataReference).flatMap((row) => {
            return row.cells.filter(item => item.type === "number" && item.column !== config.i18n.collector.total && item.column !== config.i18n.collector.ytd
                && item.column !== config.i18n.collector.ytg && item.block !== "DELTA").map((item) => {
                return {
                    id: item.id,
                    block: item.block,
                    value: item.value,
                    date: item.column,
                };
            });
        });

        client.collector.collectorUpdateCollectorEntryItems(entryId, dataToUpdate)
            .then((collectorEntry) => {
                setEntry(collectorEntry);
                setChanged(false);
                setIsSaving(false);
            })
            .catch((error) => {
                notify.error(error, "collector.entry.save");
                setIsSaving(false);
            });
    };

    const submitForReviewCollectorEntry = () => {
        client.collector.collectorSubmitForReviewCollectorEntry(entryId)
            .then(() => {
                navigate(`/collector/${collectorId}`);
            })
            .catch((error) => {
                notify.error(error, "collector.entry.submit.for.review");
            });
    };

    const approveCollectorEntry = () => {
        client.collector.collectorApproveCollectorEntry(entryId)
            .then(() => {
                navigate(`/collector/${collectorId}`);
            })
            .catch((error) => {
                notify.error(error, "collector.entry.approve");
            });
    };

    const rejectCollectorEntry = () => {
        client.collector.collectorRejectCollectorEntry(entryId)
            .then(() => {
                navigate(`/collector/${collectorId}`);
            })
            .catch((error) => {
                notify.error(error, "collector.entry.reject");
            });
    };

    const limitRangeTotal = (column, values) => {
        switch (column) {
            case config.i18n.collector.total:
                return values;
            case config.i18n.collector.ytd:
                return Object.entries(values)
                    .filter(([date]) => isActualDate(date, collector.cutoff_date))
                    .map(([, value]) => value);
            case config.i18n.collector.ytg:
                return Object.entries(values)
                    .filter(([date]) => isForecastDate(date, collector.cutoff_date))
                    .map(([, value]) => value);
        }
    };

    const computeTotalValue = (cell, allCells, values) => {
        if (cell?.total_aggregation) { // total is custom, i.e, specifically determined by config csv
            const calculated_entries_total = cell?.total_calculated_entries.map((entryId) => {
                const total_calculated_entry = { ...allCells.find(row => row.collector_entry_item_template_id === entryId), block: cell?.block, column: cell?.column };
                const total_calculated_entry_values = total_calculated_entry?.values[cell.block];

                return total_calculated_entry?.total_aggregation ? computeTotalValue(total_calculated_entry, allCells, total_calculated_entry_values)
                    : computeOperator(Object.values(limitRangeTotal(cell.column, total_calculated_entry_values)), "SUM");
            });

            // total = total(calculated_entry1) (total_operator) total(calculated_entry2)
            return computeOperator(calculated_entries_total, cell?.total_aggregation);
        }

        return computeOperator(Object.values(limitRangeTotal(cell.column, values)), cell.isPercentage ? "AVERAGE" : "SUM"); // "normal" total
    };

    const updateBlock = (block, calculatedValuesBlock = block) => {
        return block.map((row) => {
            const values = {}; // needed for total computations
            return {
                ...row,
                cells: row?.cells.map((cell) => {
                    if (cell.column === config.i18n.collector.ytd || cell.column === config.i18n.collector.ytg || cell.column === config.i18n.collector.total) {
                        let total;

                        if (cell.block === "DELTA") {
                            // get total value of actual and reference items
                            const delta_values = ["ACTUAL", "REFERENCE"].map((block) => {
                                const calculated_entry = { ...calculatedValuesBlock.find(row => row.id === cell.id), block: cell?.block, column: cell?.column };
                                return computeTotalValue(calculated_entry, calculatedValuesBlock, calculated_entry.values[block]);
                            });

                            total = computeOperator(delta_values, "DELTA"); // use total values of actual and reference to compute delta
                        } else {
                            total = computeTotalValue(cell, calculatedValuesBlock, values);
                        }

                        return {
                            ...cell,
                            value: total,
                            format: formatter(total, cell.isPercentage), // update formatter based on the new value
                        };
                    }

                    const value = calculatedValuesBlock.find(row => row.id === cell.id)?.values[cell.block][cell.column];

                    if (cell.type === "number") {
                        values[cell.column] = value; // do not push row name (text)

                        return {
                            ...cell,
                            value: value,
                            format: formatter(value, cell.isPercentage), // update formatter based on the new value
                            style: {
                                ...cell.style,
                                color: getFontColor(!cell.nonEditable, value), // update font color to red if number is negative
                            },
                        };
                    }

                    return cell;
                }),
            };
        });
    };

    useEffect(() => {
        // to update calculated values & total
        // there can be a chain of N calculated values therefore we need to have this computations inside a useEffect
        // as we do not know how many times it needs to be 'called'
        if (items) {
            const newData = items.map(item => ({
                ...item,
                values: Object.fromEntries(
                    ["ACTUAL", "REFERENCE", "DELTA"].map(block => [
                        block,
                        Object.fromEntries(
                            Object.entries(item.values[block]).map(([key, value]) => {
                                if (isReadOnly && block !== "DELTA") {
                                    return [key, value]; // if a cell is external, just use the DB value
                                }

                                let val;
                                const aggregation = isActualDate(key, collector.cutoff_date) ? item?.aggregation_actual : item?.aggregation_forecast;
                                let calculated_entries = isActualDate(key, collector.cutoff_date) ? item?.calculated_entries_actual : item?.calculated_entries_forecast;
                                calculated_entries = calculated_entries ? calculated_entries.map(entryId => items.find(row => row.collector_entry_item_template_id === entryId)) : [];

                                // items computes the calculated values
                                if (block === "DELTA") {
                                    val = computeOperator([item.values["ACTUAL"][key], item.values["REFERENCE"][key]], "DELTA"); // delta of actual and reference values
                                } else if ((aggregation !== "ONE" && !isEmpty(calculated_entries) && calculated_entries.every(entry => entry !== null && entry !== undefined)) || aggregation === "ONE") { // if a value is calculated and has not yet been calculated, let's compute it
                                    val = computeOperator(calculated_entries.map(entry => entry?.values[block][key]), aggregation);
                                } else {
                                    val = value;
                                }

                                return [key, val];
                            }),
                        ),
                    ]),
                ),
            }));

            // no need to set the state if it does not change
            if (!isEqual(newData, items)) {
                setItems(newData);
            }
        }

        if (dataReference) {
            const newData = updateBlock(dataReference, items);

            if (!isEqual(newData, dataReference)) {
                setDataReference(newData);
            }
        }
        if (dataActual) {
            const newData = updateBlock(dataActual, items);

            if (!isEqual(newData, dataActual)) {
                setDataActual(newData);
            }
        }
        if (dataDelta) {
            const newData = updateBlock(dataDelta, items);

            if (!isEqual(newData, dataDelta)) {
                setDataDelta(newData);
            }
        }
    }, [items, dataActual, dataDelta, dataReference]);

    useEffect(() => {
        client.collector.collectorGetCollector(collectorId)
            .then((collector) => {
                setCollector(collector);

                client.collector.collectorGetCollectorEntry(entryId)
                    .then((collectorEntry) => {
                        setEntry(collectorEntry);

                        client.collector.collectorListCollectorEntryItems(entryId)
                            .then((items) => {
                                setItems(items);
                                setRows(items.map(item => ({
                                    label: item?.name,
                                    indentation: item?.indentation || 0,
                                    text_style: item?.text_style || "normal",
                                })));
                                setIsReadOnly(items.every(item => item?.read_only));
                                client.collector.collectorListCollectorEntryExternalItems(entryId).then((externalItems) => {
                                    !isEmpty(externalItems) ? setExtEntriesNames([
                                        ...new Set(
                                            externalItems
                                                .map(item => item.collector_entry_name)
                                                .filter(name => hasValue(name)),
                                        ),
                                    ].sort()) : setExtEntriesNames(null); // sort names alphabetically
                                    setDataActual(items.filter(item => item?.is_visible).map((item, index) =>
                                        getRowCells(item, index, item.permission_actual, item.permission_forecast, items, collector, collectorEntry, "ACTUAL")));
                                    setDataReference(items.filter(item => item?.is_visible).map((item, index) =>
                                        getRowCells(item, index, item.permission_actual, item.permission_forecast, items, collector, collectorEntry, "REFERENCE")));
                                    setDataDelta(items.filter(item => item?.is_visible).map((item, index) =>
                                        getRowCells(item, index, "READ_ONLY", "READ_ONLY", items, collector, collectorEntry, "DELTA")));
                                })
                                    .catch(() => {
                                        setItems(null);
                                        setDataActual(null);
                                        setDataReference(null);
                                        setDataDelta(null);
                                    });
                            })
                            .catch((error) => {
                                setRows(null);
                                notify.error(error, "collector.entry.items.fetch");
                            });
                    })
                    .catch((error) => {
                        setEntry(null);
                        notify.error(error, "collector.entry.fetch");
                    });
            })
            .catch((error) => {
                setCollector(null);
                notify.error(error, "collector.fetch");
            });
    }, []);

    const columns = getAllMonthsBetweenDates(dayjs(collector?.start_date), dayjs(collector?.end_date));

    const formattedColumns = [
        ...columns.map(date => formatYearMonth(date, config.locale)),
        config.i18n.collector.ytd,
        config.i18n.collector.ytg,
        config.i18n.collector.total,
    ];

    const headerColumns = [
        { columnId: "corner", width: 300 },
        ...columns.map(col => ({ columnId: col, width: 90 })),
        { columnId: config.i18n.collector.ytd, width: 90 },
        { columnId: config.i18n.collector.ytg, width: 90 },
        { columnId: config.i18n.collector.total, width: 90 },
    ];

    const getHeaderRow = (block) => {
        return {
            rowId: "header",
            cells: [
                { type: "header", text: block, style: { background: theme.palette.grey["700"], color: "white", fontWeight: "bold" } }, // corner indicator
                ...formattedColumns.map((col) => { // date columns
                    return {
                        type: "header",
                        text: col,
                        style: {
                            background: theme.palette.grey["700"],
                            color: "white",
                            justifyContent: "center",
                        },
                    };
                })],
        };
    };

    return (
        <AdvisorContainer
            title={entry?.name}
            loading={!dataActual || !dataReference || !dataDelta || !entry || !rows || !columns || !collector || !items || !headerColumns}
            loadingLabel={config.i18n.collector.loading}
            minWidth={0}
            maxWidth="xl"
            maxHeight="calc(100vh - 70px)" // Ensures the "sticky header"
        >
           {!isEmpty(extEntriesNames) ? (
              <Alert severity="info" style={{ marginBottom: 20, border: `1px solid ${theme.palette.info.light}` }}>
                <Typography variant="subtitle1" fontWeight="bold" fontSize={14} gutterBottom>
                  {config.i18n.collector.alert}
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {extEntriesNames.map((name, index) => (
                    <Chip
                        key={index}
                        label={name}
                        size="small"
                        style={{ margin: "2px" }}
                    />
                  ))}
                </Box>
              </Alert>
           ) : null}
            <div style={{ maxWidth: 315 + (columns.length + 3) * 90, overflowX: "auto", marginBottom: 20, fontSize: 14 }}>
                <div style={{ marginBottom: 20 }}>
                    <ReactGrid
                        rows={dataActual ? [getHeaderRow(config.i18n.collector.header.forecast), ...dataActual] : []}
                        columns={headerColumns}
                        stickyLeftColumns={1}
                        stickyRightColumns={3}
                        stickyTopRows={1}
                        onCellsChanged={handleCellEdit}
                        enableRangeSelection
                    />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <ReactGrid
                        rows={dataReference ? [getHeaderRow(config.i18n.collector.header.budget), ...dataReference] : []}
                        columns={headerColumns}
                        stickyLeftColumns={1}
                        stickyRightColumns={3}
                        stickyTopRows={1}
                        onCellsChanged={handleCellEdit}
                        enableRangeSelection
                    />
                </div>
                <div>
                    <ReactGrid
                        rows={dataDelta ? [getHeaderRow(config.i18n.collector.header.delta), ...dataDelta] : []}
                        columns={headerColumns}
                        stickyLeftColumns={1}
                        stickyRightColumns={3}
                        stickyTopRows={1}
                        onCellsChanged={handleCellEdit}
                        enableRangeSelection
                    />
                </div>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
                <Button
                    variant="contained"
                    component={RouterLink}
                    title={config.i18n.button.cancel}
                    color="grey"
                    style={{ minWidth: 100 }}
                    to={`/collector/${collectorId}`}
                >
                    {config.i18n.button.cancel}
                </Button>
                {!isReadOnly
                    ? (
                        <LoadingButton
                            variant="contained"
                            startIcon={<SaveIcon />}
                            component={RouterLink}
                            title={config.i18n.button.save}
                            style={{ minWidth: 100 }}
                            loading={isSaving}
                            disabled={!changed || (!isPowerUser(entry?.permission) && !isNormalUserEditing(entry)) || isSaving}
                            onClick={() => saveCollectorEntry()}
                        >
                            {config.i18n.button.save}
                        </LoadingButton>
                        ) : null}
                {isPowerUser(entry?.permission) && !isReadOnly
                    ? (
                        <>
                            <Button
                                variant="contained"
                                startIcon={<CheckIcon />}
                                component={RouterLink}
                                title={config.i18n.button.approve}
                                disabled={changed || entry?.status === "PENDING" || entry?.status === "APPROVED"}
                                color="success"
                                style={{ minWidth: 100 }}
                                onClick={() => approveCollectorEntry()}
                            >
                                {config.i18n.button.approve}
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<CloseIcon />}
                                component={RouterLink}
                                title={config.i18n.button.reject}
                                disabled={changed || entry?.status === "PENDING" || (entry?.status !== "IN_REVIEW" && entry?.status !== "APPROVED")}
                                color="error"
                                style={{ minWidth: 100 }}
                                onClick={() => rejectCollectorEntry()}
                            >
                                {config.i18n.button.reject}
                            </Button>
                        </>
                        ) : null}
                {!isPowerUser(entry?.permission) && !isReadOnly
                    ? (
                        <Button
                            variant="contained"
                            startIcon={<CheckIcon />}
                            component={RouterLink}
                            title={config.i18n.button.submit_for_review}
                            disabled={changed || !isNormalUserEditing(entry)}
                            color="success"
                            style={{ minWidth: 200 }}
                            onClick={() => submitForReviewCollectorEntry()}
                        >
                            {config.i18n.button.submit_for_review}
                        </Button>
                        ) : null}
            </div>
        </AdvisorContainer>
    );
};

export default CollectorEntryItemsEdit;
