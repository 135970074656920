import React from "react";

// NOTE: class component is used here since there is no react hook with the same behavior as componentDidCatch, so lint is ignored in this scenario.
// Since this component is used to cover unexpected cases and are not supposed to happen. This approach works
// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidCatch(error, info) {
        // eslint-disable-next-line react/prop-types
        this.props.navigate("/error");
    }

    render() {
        // eslint-disable-next-line react/prop-types
        return this.props.children;
    }
}

export default ErrorBoundary;
