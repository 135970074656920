import { createTheme } from "@mui/material";
import createPalette from "./palette";

const originalTheme = createTheme({});

const theme = createTheme({
    palette: createPalette(originalTheme.palette),
    components: {
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    // increase the icon size and make it white.
                    "&.Mui-active": {
                        transform: "scale(1.5)",
                    },
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    // increase the font size and make it white.
                    "&.Mui-active": {
                        ".MuiTypography-root": {
                            fontSize: "18pt",
                            marginLeft: "6px",
                        },
                    },
                },
            },
        },
    },
});

export default theme;
