"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var ReportService = /** @class */ (function () {
    function ReportService() {
    }
    /**
     * List Reports
     * @param page
     * @param limit
     * @returns PaginatedResponse_Report_ The paginated list of reports.
     * @throws ApiError
     */
    ReportService.reportListReports = function (page, limit) {
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/report',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Create Report
     * @param requestBody
     * @returns Report The created report.
     * @throws ApiError
     */
    ReportService.reportCreateReport = function (requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/report',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * List Report Templates
     * @returns ReportTemplate The list of report templates.
     * @throws ApiError
     */
    ReportService.reportListReportTemplates = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/report/template',
        });
    };
    /**
     * Clone Report
     * @param reportId
     * @param requestBody
     * @returns Report The cloned report.
     * @throws ApiError
     */
    ReportService.reportCloneReport = function (reportId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/report/{report_id}/clone',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get Report
     * @param reportId
     * @returns Report The report.
     * @throws ApiError
     */
    ReportService.reportGetReport = function (reportId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/report/{report_id}',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Delete Report
     * @param reportId
     * @returns Report The deleted report.
     * @throws ApiError
     */
    ReportService.reportDeleteReport = function (reportId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/report/{report_id}',
            path: {
                'report_id': reportId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Update Report
     * @param reportId
     * @param requestBody
     * @returns Report The updated report.
     * @throws ApiError
     */
    ReportService.reportUpdateReport = function (reportId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/report/{report_id}',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Compute Report
     * @param reportId
     * @param requestBody
     * @returns ReportResults The computed report.
     * @throws ApiError
     */
    ReportService.reportComputeReport = function (reportId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/report/{report_id}/compute',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Download Report
     * @param reportId
     * @param requestBody
     * @returns any The report to be download.
     * @throws ApiError
     */
    ReportService.reportDownloadReport = function (reportId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/report/{report_id}/download',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get Report Template Intersecting Dimensions
     * @param reportTemplateId
     * @returns AnalysisDimensionCustomizationOptions The list of report template intersecting dimensions.
     * @throws ApiError
     */
    ReportService.reportGetReportTemplateIntersectingDimensions = function (reportTemplateId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/report/{report_template_id}/intersecting_dimensions',
            path: {
                'report_template_id': reportTemplateId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get Report Template Description
     * @param reportTemplateId
     * @returns string The description of a report template.
     * @throws ApiError
     */
    ReportService.reportGetReportTemplateDescription = function (reportTemplateId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/report/{report_template_id}/description',
            path: {
                'report_template_id': reportTemplateId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    return ReportService;
}());
exports.ReportService = ReportService;
