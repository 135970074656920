"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScenarioService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var ScenarioService = /** @class */ (function () {
    function ScenarioService() {
    }
    /**
     * List Scenarios
     * @param page
     * @param limit
     * @returns PaginatedResponse_Scenario_ The paginated list of scenarios.
     * @throws ApiError
     */
    ScenarioService.scenarioListScenarios = function (page, limit) {
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/scenario/list',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * List Scenario Templates
     * @returns ScenarioTemplate The list of scenario templates.
     * @throws ApiError
     */
    ScenarioService.scenarioListScenarioTemplates = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/scenario/template/list',
        });
    };
    /**
     * List Scenario Template Series
     * @param templateId
     * @returns ScenarioSeries The list of the scenario's series.
     * @throws ApiError
     */
    ScenarioService.scenarioListScenarioTemplateSeries = function (templateId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/scenario/template/{template_id}',
            path: {
                'template_id': templateId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Create Scenario
     * @param requestBody
     * @returns Scenario The created scenario.
     * @throws ApiError
     */
    ScenarioService.scenarioCreateScenario = function (requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/scenario',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Clone Scenario
     * @param scenarioId
     * @param requestBody
     * @returns Scenario The cloned scenario.
     * @throws ApiError
     */
    ScenarioService.scenarioCloneScenario = function (scenarioId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/scenario/{scenario_id}/clone',
            path: {
                'scenario_id': scenarioId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Delete Scenario
     * @param scenarioId
     * @returns Scenario The deleted scenario.
     * @throws ApiError
     */
    ScenarioService.scenarioDeleteScenario = function (scenarioId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/scenario/{scenario_id}/delete',
            path: {
                'scenario_id': scenarioId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Update Scenario
     * @param scenarioId
     * @param requestBody
     * @returns Scenario The updated scenario.
     * @throws ApiError
     */
    ScenarioService.scenarioUpdateScenario = function (scenarioId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/scenario/{scenario_id}/edit',
            path: {
                'scenario_id': scenarioId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * List Scenario Series
     * @param scenarioId
     * @returns ScenarioWithSeries The list of the scenario's series.
     * @throws ApiError
     */
    ScenarioService.scenarioListScenarioSeries = function (scenarioId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/scenario/{scenario_id}',
            path: {
                'scenario_id': scenarioId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    return ScenarioService;
}());
exports.ScenarioService = ScenarioService;
