"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Document = void 0;
var Document;
(function (Document) {
    Document["LEDGER_INVOICE"] = "LEDGER_INVOICE";
    Document["LEDGER_BUDGET"] = "LEDGER_BUDGET";
    Document["LEDGER_ORDER"] = "LEDGER_ORDER";
    Document["LEDGER_EXTERNAL"] = "LEDGER_EXTERNAL";
})(Document || (exports.Document = Document = {}));
