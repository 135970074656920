"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var QueryService = /** @class */ (function () {
    function QueryService() {
    }
    /**
     * Run
     * @param analysisId
     * @param requestBody
     * @returns AnalysisResult The query result.
     * @throws ApiError
     */
    QueryService.queryRun = function (analysisId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/query',
            query: {
                'analysis_id': analysisId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Download Slide
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    QueryService.queryDownloadSlide = function (requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/query/slide',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    return QueryService;
}());
exports.QueryService = QueryService;
