"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Action = void 0;
var Action;
(function (Action) {
    Action["CREATE"] = "CREATE";
    Action["READ"] = "READ";
    Action["UPDATE"] = "UPDATE";
    Action["DELETE"] = "DELETE";
    Action["UPDATE_FUTURE"] = "UPDATE_FUTURE";
    Action["SYNC"] = "SYNC";
    Action["COMMIT"] = "COMMIT";
})(Action || (exports.Action = Action = {}));
