import {
    formatTooltipValue,
    getAxisTooltip,
    getAxisV,
    getBase,
    getFormattedValue,
    getFormatterFromLabelType,
    getYSeriesData,
    defaultValueLabelSetting,
} from "./common";
import { formatTemplate } from "../../util/formatter";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    function formatBin(value, index) {
        const formatterType = getFormatterFromLabelType(data.label_type["x"], true) || "";
        if (value[0] === null) {
            const template = `< {{ ${formatterType} var1 }}`;
            return formatTemplate(template, { var1: value[1] }, config.locale, config.i18n);
        } else if (value[1] === null) {
            const template = `≥ {{ ${formatterType} var1 }}`;
            return formatTemplate(template, { var1: value[0] }, config.locale, config.i18n);
        } else {
            const template = `[{{ ${formatterType} var1 }} ; {{ ${formatterType} var2 }}`;
            // making sure the last bin shows as a closed interval.
            const endingChar = index === data.result.length - 1 ? `]` : `[`;

            return formatTemplate(template + endingChar, { var1: value[0], var2: value[1] }, config.locale, config.i18n);
        }
    }

    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),

        tooltip: {
            ...getAxisTooltip(data, config, baseFontSize).tooltip,
            formatter: (params) => {
                const name = params[0].data.id;
                const returnVal = `${name} <br/> ${params[0].marker}`;

                // if there isn't value, format it with the special label of EPSILON - NA.
                // this is useful on line charts, with null values.
                let value = params[0].data.value;
                if (value === null) {
                    value = Number.EPSILON;
                }

                return returnVal + formatTooltipValue(data, "y", value, config);
            },
        },

        series: getYSeriesData(data, formatBin).map(series =>
            ({
                type: "bar",
                data: series,
                barWidth: "99%",
                label: defaultValueLabelSetting("top", baseFontSize, config,
                    value => getFormattedValue(value.data.value, "y", data, config)),
            })),

        xAxis: {
            ...getAxisV(data, config, baseFontSize).xAxis,
            axisLabel: {
                ...getAxisV(data, config, baseFontSize).xAxis.axisLabel,
                height: 2 * 0.40 * baseFontSize,
                margin: 0.20 * baseFontSize,
                lineHeight: 0.40 * baseFontSize,
                overflow: "break",
            },

            // specify how to show a 2 position array.
            data: data.result.map((el, index) => formatBin(el.label, index)),
        },
    };
};
