import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import { useContext } from "react";

import { AppContext } from "../AppRouter";
import WarningIcon from "@mui/icons-material/Warning";

const DiscardDialog = ({ open, onDiscard, onCancel }) => {
    const { config } = useContext(AppContext);

    return (
        <Dialog
            maxWidth="xs"
            open={open}
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <WarningIcon color="warning" sx={{ marginRight: 1 }} />
                    {config.i18n.dialog.discard_changes_title}
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                {config.i18n.dialog.discard_changes}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()}>
                    {config.i18n.button.cancel}
                </Button>
                <Button
                    onClick={() => onDiscard()}
                >
                    {config.i18n.button.discard}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DiscardDialog.propTypes = {
    open: PropTypes.bool,
    onDiscard: PropTypes.func,
    onCancel: PropTypes.func,
};

export default DiscardDialog;
