/**
 * Source: https://codesandbox.io/s/1106l?file=/AspectRatioBox.js:74-84
 * Adapted to MUI 5 with: https://mui.com/system/styled/
 */
import React from "react";
import { styled } from "@mui/system";
import PropTypes from "prop-types";

const RootDiv = styled("div")({
    position: "relative",
});

const WrapperDiv = styled("div")({
    "position": "absolute",
    "top": 0,
    "left": 0,
    "right": 0,
    "bottom": 0,
    "& > *": { height: "100%", width: "100%" },
});

const AspectRatioBox = ({ children, ratio = 1 }) => {
    return (
        <RootDiv>
            <WrapperDiv>{children}</WrapperDiv>
            <div style={{ paddingBottom: (1 / ratio) * 100 + "%" }} />
        </RootDiv>
    );
};

AspectRatioBox.propTypes = {
    children: PropTypes.any,
    ratio: PropTypes.number,
};

export default AspectRatioBox;
