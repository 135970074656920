"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserType = void 0;
var UserType;
(function (UserType) {
    UserType["PROCUREMENT_OWNER"] = "PROCUREMENT_OWNER";
    UserType["BUSINESS_OWNER"] = "BUSINESS_OWNER";
})(UserType || (exports.UserType = UserType = {}));
