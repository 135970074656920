import React from "react";
import CircularLoading from "./loading/CircularLoading";
import { hasValue } from "../util/util";
import { useContext, useEffect, useState } from "react";
import { Autocomplete, debounce, InputAdornment, TextField } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Search } from "@mui/icons-material";
import PropTypes from "prop-types";
import { uniq } from "lodash";
import { AppContext } from "../AppRouter";

export function SearchBar({ disabled, value, sx }) {
    const { client, config, notify } = useContext(AppContext);

    const [query, setQuery] = useState();
    const [request, setRequest] = useState();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [analyses, setAnalyses] = useState();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // this works because AutoComplete component is not controlled
    // (it does not uses the query value as input)
    const setQueryDebounced = debounce(setQuery, 250);

    const handleClickAway = () => {
        setAnalyses(null);
        setOpen(false);
        setLoading(false);
    };

    useEffect(() => {
        if (!query) {
            setAnalyses(null);
            setLoading(false);
        } else {
            setLoading(true);

            // cancel previous request
            if (request) {
                request.cancel();
            }

            const requestPremise = client.analysis.analysisSearch(query, 0, 5);

            setRequest(requestPremise);

            requestPremise
                .then((data) => {
                    setAnalyses(data.response);

                    if (config.visual?.debug) {
                        const uniqueQuestions = uniq(data.response.map(analysis => analysis.question));
                        if (uniqueQuestions.length !== data.response.length) {
                            console.warn("Duplicated questions on analyses search.");
                            console.warn(data.response);

                            notify.debug("Duplicated questions on analyses search.");
                        }
                    }

                    setOpen(true);
                    setLoading(false);
                })
                .catch((error) => {
                    // ignore cancelation errors
                    if (error?.name === "CancelError") {
                        return;
                    }

                    setAnalyses(null);
                    setOpen(false);
                    setLoading(false);
                    notify.error(error, "analysis.search");
                });
        }
    }, [query]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Autocomplete
                disabled={disabled}
                freeSolo
                value={value || ""}
                options={analyses || []}
                // if the selected option has question, let's use its ID.
                // else let's keep using the option itself as a key.
                // this avoids duplicated questions breaking the component, because using label (question) as key may cause duplicated keys.
                getOptionKey={option => option?.id || option}
                getOptionLabel={(option) => {
                    // if the selected option has question, let's show it.
                    // else if the user pressed enter without any options, a string will be passed.
                    //      So let's keep showing the user input.
                    // else just clear it.
                    if (option["question"]) {
                        return option.question;
                    } else if (typeof option === "string") {
                        return option;
                    } else {
                        return "";
                    }
                }}
                // needed to make autoHighlight work.
                open={open}
                filterOptions={x => x}
                renderInput={params =>
                    (
                        <TextField
                            {...params}
                            placeholder={config.i18n.ask_me.question_tip}
                            autoFocus
                            InputProps={{
                                ...params.InputProps,
                                "data-cy": "ask_me-input",
                                "startAdornment": (
                                    <InputAdornment position="start" sx={{ ml: 1 }}>
                                        <Search />
                                    </InputAdornment>
                                ),
                                "endAdornment": (
                                    <>
                                        {loading ? <CircularLoading size={25} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                onInputChange={(ev, value) => {
                    // only issue a query if the user changes manually the input.
                    if (ev?.type === "change") {
                        setQueryDebounced(value);
                    }
                }}
                onChange={(ev, value) => {
                    // ignore setting an analysis when the value is empty
                    // or it isn't one of the avaliable options.
                    if (!hasValue(value) || typeof value === "string") {
                        return;
                    }

                    setOpen(false);
                    navigate({ pathname: `../ask/${value.id}`, search: searchParams.toString() });
                }}
                sx={sx}
            />
        </ClickAwayListener>
    );
}

SearchBar.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.string,
    sx: PropTypes.object,
};
