export function parseCustomizationParameters(customizationParameters) {
    const elementsByType = customizationParameters.reduce((acc, p) => {
        if (!acc[p.type]) {
            acc[p.type] = [];
        }

        acc[p.type].push(p);

        return acc;
    }, {});

    elementsByType.CHART = elementsByType.CHART && elementsByType.CHART.length && elementsByType.CHART[0] ? elementsByType.CHART[0] : null;
    elementsByType.ANNUALIZE = elementsByType.ANNUALIZE && elementsByType.ANNUALIZE.length ? elementsByType.ANNUALIZE[0] : null;
    elementsByType.DATE_AGG = elementsByType.DATE_AGG && elementsByType.DATE_AGG.length ? elementsByType.DATE_AGG[0] : null;
    elementsByType.LIMIT = elementsByType.LIMIT && elementsByType.LIMIT.length ? elementsByType.LIMIT[0] : null;
    elementsByType.IGNORE_NULL = elementsByType.IGNORE_NULL && elementsByType.IGNORE_NULL.length ? elementsByType.IGNORE_NULL[0] : null;
    elementsByType.HIDE_INSIGHTS = elementsByType.HIDE_INSIGHTS && elementsByType.HIDE_INSIGHTS.length ? elementsByType.HIDE_INSIGHTS[0] : null;
    elementsByType.DATE = elementsByType.DATE && elementsByType.DATE.length ? elementsByType.DATE[0] : null;
    elementsByType.HIDE_TAIL = elementsByType.HIDE_TAIL && elementsByType.HIDE_TAIL.length ? elementsByType.HIDE_TAIL[0] : null;
    elementsByType.FOREX_DATE = elementsByType.FOREX_DATE && elementsByType.FOREX_DATE.length ? elementsByType.FOREX_DATE[0] : null;
    elementsByType.STAT_LINES = elementsByType.STAT_LINES && elementsByType.STAT_LINES.length ? elementsByType.STAT_LINES[0] : null;
    elementsByType.SCENARIO = elementsByType.SCENARIO && elementsByType.SCENARIO.length ? elementsByType.SCENARIO[0] : null;
    elementsByType.DIMENSION = elementsByType.DIMENSION && elementsByType.DIMENSION.length ? elementsByType.DIMENSION[0].options : null;

    return elementsByType;
}

export function parseDimensionFiltersCustomizationParameters(customizationParameters) {
    return customizationParameters.reduce((acc, p) => {
        if (!acc[p.type]) {
            acc[p.type] = [];
        }

        acc[p.type].push(p.dim);

        return acc;
    }, {});
}

export function initializeParameterDefaults(analysis, paramsToIgnoreDefaults) {
    return analysis.customization_parameters
        .filter(p => !paramsToIgnoreDefaults.includes(p.type))
        .reduce((acc, value) => {
            return { ...acc, [value.type]: value.default };
        }, {});
}

export function getDefaultByType(customizationParameters, type) {
    return customizationParameters.find(el => el.type === type)?.default;
}

export function getOptionsByType(customizationParameters, type) {
    return customizationParameters.find(el => el.type === type)?.options;
}
