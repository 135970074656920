"use strict";
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForecastingType = void 0;
var ForecastingType;
(function (ForecastingType) {
    ForecastingType["EXTRAPOLATED"] = "EXTRAPOLATED";
    ForecastingType["CALCULATED"] = "CALCULATED";
    ForecastingType["INITIAL_STOCK"] = "INITIAL_STOCK";
    ForecastingType["END_STOCK"] = "END_STOCK";
    ForecastingType["AVERAGE_STOCK"] = "AVERAGE_STOCK";
})(ForecastingType || (exports.ForecastingType = ForecastingType = {}));
