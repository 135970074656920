import { Box, Chip, Typography } from "@mui/material";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../AppRouter";

const ChipList = ({ selected }) => {
    const displayedValues = selected.slice(0, 3); // Limit to first 2 tags
    const remainingCount = selected.length - displayedValues.length;
    const { config } = useContext(AppContext);

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {displayedValues.map(value => (
                <Chip
                    key={value}
                    size="small"
                    label={config.i18n.dimension[value] || value?.name || value}
                    sx={{ ".MuiChip-label": { maxWidth: 90 } }}
                />
            ))}
            {remainingCount > 0 && (
                <Typography>
                    +
                    { remainingCount }
                </Typography>
            )}
        </Box>
    );
};

ChipList.propTypes = {
    selected: PropTypes.array,
};

export default ChipList;
