import React from "react";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import DimensionFilters from "../../components/DimensionFilters";
import DeleteIconButton from "../../components/button/DeleteIconButton";
import { AppContext } from "../../AppRouter";
import { Stack } from "@mui/system";

const Scope = ({ scope, scopeMetadata, disabled, shouldValidate, onUpdate }) => {
    const { config } = useContext(AppContext);
    const [expandedPanel, setExpandedPanel] = useState(scope[0].id);
    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Scope</Typography>
                {
                    !disabled
                        ? (
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                color="grey"
                                onClick={() => {
                                    const newFilterId = uuidv4();
                                    onUpdate([...scope, { id: newFilterId, name: "", filters: {} }]);
                                    setExpandedPanel(newFilterId);
                                }}
                            >
                                {config.i18n.button.add_filter}
                            </Button>
                            )
                        : null
                }
            </Stack>
            <Box my={2}>
                {
                    scope.map((el, index) => {
                        return (
                            <Accordion
                                key={el + index}
                                expanded={expandedPanel === el.id}
                                onChange={(e, expanded) => {
                                    setExpandedPanel(expanded ? el.id : null);
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                >
                                    <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
                                        <TextField
                                            label={config.i18n.procurement.scope.name_label}
                                            required
                                            disabled={disabled}
                                            error={shouldValidate ? !scope[index].name : null}
                                            helperText={shouldValidate && !scope[index].name
                                                ? config.i18n.warn.field_empty
                                                : ""}
                                            placeholder={config.i18n.procurement.scope.filter_name_placeholder}
                                            variant="outlined"
                                            size="small"
                                            value={el.name}
                                            onClick={e => e.stopPropagation()}
                                            onChange={(e) => {
                                                const newScope = [...scope];
                                                newScope.find(s => s.id === el.id).name = e.target.value;
                                                onUpdate(newScope);
                                            }}
                                        />
                                        {
                                            scope.length && !disabled
                                                ? (
                                                    <DeleteIconButton
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const newScope = [...scope].filter(s => !(s.id === el.id));
                                                            onUpdate(newScope);
                                                        }}
                                                    />
                                                    )
                                                : null
                                        }
                                    </Stack>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <DimensionFilters
                                        dimensionParameters={el.filters}
                                        onUpdate={(filters, updatedMetadata) => {
                                            const newScope = [...scope].filter(s => !(s.id === el.id));
                                            const newMetadata = { ...scopeMetadata, ...updatedMetadata };
                                            onUpdate([...newScope, {
                                                id: el.id,
                                                name: el.name,
                                                filters: filters,
                                            }], newMetadata);
                                        }}
                                        customizationParameters={
                                            [
                                                {
                                                    type: "DIMENSION",
                                                    dim: "VENDOR",
                                                },
                                                {
                                                    type: "DIMENSION",
                                                    dim: "OWNER",
                                                },
                                                {
                                                    type: "HIERARCHY",
                                                    dim: "ORG_UNIT",
                                                },
                                                {
                                                    type: "HIERARCHY",
                                                    dim: "CATEGORY",
                                                },
                                            ]
                                        }
                                        metadata={scopeMetadata}
                                        disabled={disabled}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        );
                    })
                }
            </Box>
        </>
    );
};

Scope.propTypes = {
    scope: PropTypes.array,
    scopeMetadata: PropTypes.object,
    disabled: PropTypes.bool,
    shouldValidate: PropTypes.bool,
    onUpdate: PropTypes.func,
};

export default Scope;
