import { getAxisTooltip, getAxisV, getBase, getLegend, getYSeries } from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        ...getLegend(data, config, baseFontSize),
        ...getYSeries(data, "line", baseFontSize, config, {
            areaStyle: {},
        }),
    };
};
