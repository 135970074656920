// Cell with color and border.
import { Box, Grid, IconButton, List, Paper, Popover, Typography } from "@mui/material";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

export const ColoredCell = ({ colorString, borderColorString, children }) =>
    (
        <Grid item xs={3.46}>
            <Box
                sx={{ backgroundColor: colorString, height: 230, border: `1px solid`, borderColor: borderColorString, borderRadius: 1 }}
            >
                <List disablePadding dense sx={{ maxHeight: "100%", overflowY: "auto" }}>
                    {children}
                </List>
            </Box>
        </Grid>
    );

ColoredCell.propTypes = {
    colorString: PropTypes.string,
    borderColorString: PropTypes.string,
    children: PropTypes.any,
};

// Cell with scale on XX axis.
export const ScaleXCell = ({ children }) =>
    (
        <Grid item xs={3.46}>
            <Typography align="center">
                {children}
            </Typography>
        </Grid>
    );

ScaleXCell.propTypes = {
    children: PropTypes.any,
};

// Cell with scale on YY axis.
export const ScaleYCell = ({ children }) =>
    (
        <Grid item xs={0.81}>
            <Typography
                align="center"
                sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", height: "100%" }}
            >
                {children}
            </Typography>
        </Grid>
    );

ScaleYCell.propTypes = {
    children: PropTypes.any,
};

// Cell with label, edit button and popover to edit.
export const LabelCell = ({ size, label, anchor, setAnchor, children }) =>
    (
        <Grid item xs={size === "small" ? 0.81 : 3.46}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                <Typography align="center">
                    {label}
                </Typography>
                <IconButton color="info" onClick={e => setAnchor(e.currentTarget)}>
                    <EditIcon />
                </IconButton>
                <Popover
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    onClose={() => setAnchor(null)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <Paper sx={{ px: 3, py: 1, width: 300 }}>
                        {children}
                    </Paper>
                </Popover>
            </Box>
        </Grid>
    );

LabelCell.propTypes = {
    size: PropTypes.string,
    label: PropTypes.string,
    anchor: PropTypes.object,
    setAnchor: PropTypes.func,
    children: PropTypes.any,
};

export const EmptyCell = ({ size }) => <Grid item xs={size === "small" ? 0.81 : 3.46} />;

EmptyCell.propTypes = {
    size: PropTypes.string,
};
