import dayjs from "dayjs";
import {
    defaultFontColor,
    defaultLabelFontSize,
    defaultValueLabelSetting,
    getAxisTooltip as getBaseAxisTooltip,
    getAxisTooltipFormatter,
    getAxisV as getBaseAxisV,
} from "../common";
import { formatCompactInteger, formatQuarter, formatValue } from "../../../util/formatter";
import { isForecastingEnabled } from "../../../util/feature_flag_util";
import { convertToISODate } from "../../../util/util";

const axisLabelFormatterQuarter = (value) => {
    // Formatted to be month/day; display year only in the first label
    const date = new Date(value);
    if (date.getDate() === 1 && date.getMonth() % 3 === 0) {
        return formatQuarter(value);
    }
    return "";
};

const axisLabelFormatterMonth = () => {
    // Formatted to be month/day; display year only in the first label
    return {
        year: "{monthStyle|{MMM}'{yy}}", // So that january doesn't go empty
        month: "{monthStyle|{MMM}'{yy}}",
    };
};

const axisLabelFormatterYear = () => {
    return {
        year: "{yearStyle|{yyyy}}",
    };
};

export function getAxisV(data, config, baseFontSize, extraColumns) {
    // Fiscal year is done in another way, it uses x as an id for a fiscal year from the DB and the metadata to get the label
    // while here the x is a timestamp
    let axisLabelFormatter = axisLabelFormatterYear();
    if (data.parameters.DATE_AGG == "DATE_YEAR_QUARTER") {
        axisLabelFormatter = axisLabelFormatterQuarter;
    } else if (data.parameters.DATE_AGG == "DATE_YEAR_MONTH") {
        axisLabelFormatter = axisLabelFormatterMonth();
    }

    return {
        ...getBaseAxisV(data, config, baseFontSize, extraColumns),
        xAxis: {
            name: data.labels?.x || "",
            nameLocation: "middle",
            nameGap: 1.5 * baseFontSize,
            nameTextStyle: {
                fontSize: 0.5 * baseFontSize,
            },
            type: "time",
            minInterval: 3600 * 24 * 1000 * (data.parameters["DATE_AGG"] === "DATE_YEAR" ? 355 : 30),
            max: "dataMax",
            axisLabel: {
                formatter: axisLabelFormatter,
                rich: {
                    yearStyle: {
                        fontSize: defaultLabelFontSize(baseFontSize),
                        color: defaultFontColor,
                    },
                    monthStyle: {
                        fontSize: defaultLabelFontSize(baseFontSize),
                        color: defaultFontColor,
                    },
                },
                showMinLabel: true,
                showMaxLabel: true,
            },
        },
    };
}

export function getYSeriesData(data) {
    // [] * number of series.
    const ySeries = [...Array(data.result[0].y.length)].map(() => []);
    // [[...y1], [...y2], ...]
    data.result.forEach((result) => {
        for (let i = 0; i < result.y.length; i++) {
            // time axis require value to be a 2 position array.
            // 0 - time
            // 1 - value
            const label = data.label_type.x === "DATE"
                ? dayjs(result.label || result.x).toDate()
                : result.label || result.x;
            ySeries[i].push({
                id: label,
                name: label,
                value: [label, result.y[i]],
            });
        }
    });

    return ySeries;
}

export function getYSeries(data, type, baseFontSize, config, extraOptions = {}) {
    return {
        series: getYSeriesData(data)
            .map((series, index) => {
                return ({
                    name: index < data?.series_labels.length ? data.series_labels[index] : "", // FIXME this should not happen!
                    type: type,
                    stack: "y",
                    data: series,
                    labelLayout: {
                        hideOverlap: true,
                    },
                    symbolSize: 0.15 * baseFontSize,
                    label: index < getYSeriesData(data).length - 1
                        ? ""
                        : defaultValueLabelSetting(
                            "top",
                            baseFontSize,
                            config,
                            (value) => {
                                const id = value.data.name;
                                const sum = data.result
                                    .find(r => dayjs(r.label).isSame(dayjs(id)))
                                    .y
                                    .reduce((a, b) => a + b, 0);

                                return formatCompactInteger(sum, config.locale);
                            }),
                    ...extraOptions,
                });
            }),
    };
}

export function getAxisTooltip(data, config, baseFontSize) {
    return {
        ...getBaseAxisTooltip(data, config, baseFontSize, {
            formatter: (params) => {
                const time = params[0].data.id;
                const formatter = getFormatterFromDateAgg(data.parameters["DATE_AGG"]);
                const title = formatValue(time, formatter, config.locale, config.i18n);

                return getAxisTooltipFormatter(data, config, title, value => value.data.value[1])(params);
            },
        }),
    };
}

export function getForecastingLine(data, config, baseFontSize) {
    const isForecasting = isForecastingEnabled(config) && data.parameters?.SCENARIO != null;

    if (!isForecasting) {
        return {};
    }

    const cutoffDate = dayjs(data.parameters?.CUTOFF_DATE || config.time.forecasting?.min);

    // Forecasting line is placed between the last historical value and the first forecasted value
    const firstForecastEntry = data.result.find(value => cutoffDate.isSame(value.x, "month") || cutoffDate.isBefore(value.x, "month"));

    if (!firstForecastEntry) {
        return {};
    }

    let cutoffLineDate = dayjs(firstForecastEntry.x);

    // The middle between two date points in the chart depends on date aggregation
    switch (data.parameters.DATE_AGG) {
        case "DATE_YEAR":
            cutoffLineDate = cutoffLineDate.subtract(6, "month");
            break;
        case "DATE_YEAR_QUARTER":
            cutoffLineDate = cutoffLineDate.subtract(45, "day");
            break;
        case "DATE_YEAR_MONTH":
            cutoffLineDate = cutoffLineDate.subtract(15, "day");
            break;
    }

    cutoffLineDate = convertToISODate(cutoffLineDate);
    return {
        markLine: {
            symbol: "none",
            animation: false,
            data: [{
                xAxis: cutoffLineDate,
                lineStyle: { width: 2, color: "grey" },
                label: {
                    formatter: config.i18n.chart.lines.actual,
                    fontSize: defaultLabelFontSize(baseFontSize),
                    position: "insideEndTop",
                },
            },
            {
                xAxis: cutoffLineDate,
                lineStyle: { width: 2, color: "grey" },
                label: {
                    formatter: config.i18n.chart.lines.forecast,
                    fontSize: defaultLabelFontSize(baseFontSize),
                    position: "insideEndBottom",
                },
            }],
        },
    };
}

function getFormatterFromDateAgg(dateAgg) {
    switch (dateAgg) {
        case "DATE_YEAR":
            return "year";
        case "DATE_YEAR_MONTH":
            return "year-month";
        case "DATE_YEAR_QUARTER":
            return "year-quarter";
        default:
            throw new Error("DATE_AGG not supported for formatting.");
    }
}
