import { Box, TextField } from "@mui/material";
import UserPicker from "../../../../components/picker/UserPicker";
import { formatRatioToPercentage } from "../../../../util/formatter";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import ValueField from "../../../../components/field/ValueField";
import { hasValue } from "../../../../util/util";
import { UserType } from "../../../../client";
import { AppContext } from "../../../../AppRouter";

const BasicsTable = ({ initiative, onInitiativeValuesUpdate }) => {
    const { config } = useContext(AppContext);

    if (!initiative) {
        return null;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <TextField
                disabled
                fullWidth
                id="lever"
                size="small"
                label={config.i18n.procurement.lever.label}
                value={config.i18n.procurement.lever.values[initiative.lever]}
                sx={{ mb: 2 }}
            />
            <TextField
                disabled
                fullWidth
                id="topic"
                size="small"
                label={config.i18n.procurement.topic.label}
                value={config.i18n.procurement.topic.values[initiative.topic]}
                sx={{ mb: 2 }}
            />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <UserPicker
                    initialUserId={initiative.business_owner}
                    label={config.i18n.procurement.owner.business_owner}
                    type={UserType.BUSINESS_OWNER}
                    onUpdate={user =>
                        onInitiativeValuesUpdate(
                            {
                                ...initiative,
                                business_owner: user?.id,
                            },
                        )}
                    sx={{ mr: 1, flex: 2 }}
                />
                <ValueField
                    value={hasValue(initiative.business_owner_allocation) ? initiative.business_owner_allocation : ""}
                    formatter={value => formatRatioToPercentage(value, config.locale)}
                    initialEditFormatter={value => formatRatioToPercentage(value, config.locale, false)}
                    adornment="%"
                    size="small"
                    label={config.i18n.procurement.owner.business_owner_allocation}
                    onChange={value =>
                        onInitiativeValuesUpdate(
                            {
                                ...initiative,
                                business_owner_allocation: hasValue(value) ? value / 100 : null,
                            },
                        )}
                    sx={{ mr: 1, flex: 1 }}
                />
                <UserPicker
                    initialUserId={initiative.procurement_owner}
                    label={config.i18n.procurement.owner.procurement_owner}
                    type={UserType.PROCUREMENT_OWNER}
                    onUpdate={user =>
                        onInitiativeValuesUpdate(
                            {
                                ...initiative,
                                procurement_owner: user?.id,
                            },
                        )}
                    sx={{ mr: 1, flex: 2 }}
                />
                <ValueField
                    value={hasValue(initiative.procurement_owner_allocation) ? initiative.procurement_owner_allocation : ""}
                    formatter={value => formatRatioToPercentage(value, config.locale)}
                    initialEditFormatter={value => formatRatioToPercentage(value, config.locale, false)}
                    adornment="%"
                    size="small"
                    label={config.i18n.procurement.owner.procurement_owner_allocation}
                    onChange={value =>
                        onInitiativeValuesUpdate(
                            {
                                ...initiative,
                                procurement_owner_allocation: hasValue(value) ? value / 100 : null,
                            },
                        )}
                    sx={{ flex: 1 }}
                />
            </Box>
        </Box>
    );
};

BasicsTable.propTypes = {
    initiative: PropTypes.object,
    onInitiativeValuesUpdate: PropTypes.func,
};

BasicsTable.defaultProps = {
    onInitiativeValuesUpdate: () => {},
};

export default BasicsTable;
