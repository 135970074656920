import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import SaveIcon from "@mui/icons-material/Save";
import { AppContext } from "../../AppRouter";
import CollectorDetails from "./CollectorDetails";
import { convertToISODate } from "../../util/util";

const CloneCollector = () => {
    const { client, config, notify } = useContext(AppContext);

    const [shouldValidate, setShouldValidate] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { collectorId } = useParams();

    function isValid(name, startDate, endDate, cutoffDate) {
        return !isEmpty(name) && !isEmpty(startDate) && !isEmpty(endDate) && !isEmpty(cutoffDate);
    }

    const cloneCollector = (name, template, startDate, endDate, cutoffDate) => {
        setShouldValidate(true);
        if (isValid(name, startDate, endDate, cutoffDate)) {
            setLoading(true);
            client.collector.collectorCloneCollector(collectorId,
                {
                    name: name,
                    template: template,
                    start_date: convertToISODate(startDate),
                    end_date: convertToISODate(endDate),
                    cutoff_date: convertToISODate(cutoffDate),
                })
                .then(() => {
                    setLoading(false);
                    navigate("/collector");
                })
                .catch((error) => {
                    setLoading(false);
                    notify.error(error, "collector.clone");
                });
        }
    };

    return (
        <CollectorDetails
            operation="clone"
            title={config.i18n.collector.clone.title}
            button={config.i18n.button.save}
            icon={<SaveIcon />}
            shouldValidate={shouldValidate}
            loading={loading}
            hasTemplate
            onClick={cloneCollector}
        />
    );
};

export default CloneCollector;
