export function createPalette(originalPalette) {
    return {
        customYellow: {
            light: "#fffccc",
        },
        customGreen: {
            light: "#e0fcbc",
        },
        procurementMatrix1X1Yellow: {
            main: "hsl(64, 100%, 90%)",
            dark: "hsl(60, 100%, 50%)",
        },
        procurementMatrix1X2Green: {
            main: "hsl(85, 100%, 91%)",
            dark: "hsl(85, 100%, 81.9%)",
        },
        procurementMatrix1X3Green: {
            main: "hsl(128, 100%, 92%)",
            dark: "hsl(128, 100%, 82.8%)",
        },
        procurementMatrix2X1Orange: {
            main: "hsl(32, 100%, 94%)",
            dark: "hsl(32, 100%, 84.6%)",
        },
        procurementMatrix2X2Yellow: {
            main: "hsl(64, 100%, 95%)",
            dark: "hsl(58, 100%, 78.5%)",
        },
        procurementMatrix2X3Green: {
            main: "hsl(128, 100%, 96%)",
            dark: "hsl(128, 100%, 86.4%)",
        },
        procurementMatrix3X1Pink: {
            main: "hsl(0, 100%, 96%)",
            dark: "hsl(0, 76%, 90%)",
        },
        procurementMatrix3X2Orange: {
            main: "hsl(0, 100%, 98%)",
            dark: "hsl(0, 100%, 93%)",
        },
        procurementMatrix3X3Yellow: {
            main: "hsl(64, 100%, 98%)",
            dark: "hsl(64, 100%, 73%)",
        },
        echartsBlue: {
            main: "#5470c6",
        },
        echartsLightGreen: {
            main: "#91cc75",
        },
        echartsYellow: {
            main: "#fac858",
        },
        echartsRed: {
            main: "#ee6666",
        },
        echartsLightBlue: {
            main: "#73c0de",
        },
        echartsGreen: {
            main: "#3ba272",
        },
        echartsOrange: {
            main: "#fc8452",
        },
        echartsPurple: {
            main: "#9a60b4",
        },
        echartsPink: {
            main: "#ea7ccc",
        },
        reportHeaderRowGrey: {
            main: originalPalette.grey["400"],
        },
        reportForecastCellGrey: {
            main: originalPalette.grey["200"],
        },
    };
}

export default createPalette;
