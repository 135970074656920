import { getAxisTooltip, getAxisV, getForecastingLine, getYSeries } from "./common";
import { getBase, getLegend } from "../common";
import { getChartOptions as getAreaChartOptions } from "../stackedArea";

export function getChartOptions(title, statisticalLines, data, config, baseFontSize) {
    // If x type is not date, then we return an area chart, instead of stacked timeline.
    if (data.label_type.x !== "DATE") {
        return getAreaChartOptions(title, statisticalLines, data, config, baseFontSize);
    }

    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        ...getLegend(data, config, baseFontSize),
        ...getYSeries(data, "line", baseFontSize, config, {
            areaStyle: {},
            triggerLineEvent: true,
            ...getForecastingLine(data, config, baseFontSize),
        }),
    };
}
