import React, { useContext } from "react";

import AdvisorContainer from "../layout/AdvisorContainer";
import { AppContext } from "../AppRouter";

const Explore = () => {
    const { config } = useContext(AppContext);

    return (
        <AdvisorContainer title={config.i18n.page.explore} />
    );
};

export default Explore;
