import {
    defaultLabelFontSize,
    defaultValueLabelSetting,
    displayStatLines,
    getAxisTooltip,
    getAxisV,
    getBase,
    getFormattedValue,
    getYSeriesData,
    overrideNegativeLabelPosition,
} from "./common";

export const getChartOptions = (title, statisticalLines, data, config, baseFontSize) => {
    return {
        ...getBase(title, baseFontSize),
        ...getAxisV(data, config, baseFontSize),
        ...getAxisTooltip(data, config, baseFontSize),
        series: getYSeriesData(data).map(series =>
            ({
                type: "bar",
                data: overrideNegativeLabelPosition(series, "bottom"),
                label: defaultValueLabelSetting("top", baseFontSize, config,
                    value => getFormattedValue(value.data.value, "y", data, config)),
                markLine: {
                    data: statisticalLines && data.variables ? displayStatLines(statisticalLines, data.variables, config) : [],
                    symbol: "none",
                    lineStyle: {
                        // this style will be applied to all lines
                        width: 2,
                    },
                    label: {
                        fontSize: defaultLabelFontSize(baseFontSize),
                        formatter: function (params) {
                            return params.name + " : " + getFormattedValue(params.data.yAxis, "y", data, config);
                        },
                        position: "insideEndTop",
                    },
                    animation: false,
                    silent: true,
                },
            })),
    };
};
