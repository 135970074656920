import { OpenAPI } from "../client";

const MARKDOWN_LINK_REGEX = /\[(.+?)\]\((.*?)\)/g;

export function cleanStringForSlide(str) {
    // Remove html marks and '**' and then remove md links for their text
    const cleanChars = str.replace(/<\/?[^>]+(>|$)|[**]/g, "");
    return cleanChars.replace(MARKDOWN_LINK_REGEX, "$1");
}

export function isNotMarkdownLink(str) {
    return !str.match(MARKDOWN_LINK_REGEX);
}

export function downloadSlide(title, subtitle, insights, annotations, image64, question, onDownloadComplete, onDownloadError) {
    // the OpenAPI generated client does not handle binary responses
    // we could the generated code or maybe use another OpenAPI client generator
    // but lets open an issue to solve it in the future and carry on FIXME
    // https://github.com/ferdikoomen/openapi-typescript-codegen/pull/986
    // https://github.com/ferdikoomen/openapi-typescript-codegen/issues/1074
    const slideContent = {
        title: title,
        subtitle: subtitle,
        insights: insights,
        annotations: annotations,
        image64: image64,
    };
    const options = {
        "method": "POST",
        "content-type": "application/json",
        "headers": {
            "accept": "application/pptx",
            "authorization": "Bearer " + OpenAPI.TOKEN,
            "content-type": "application/json",
        },
        "body": JSON.stringify(slideContent),
        "mode": "cors",
        "credentials": "include",
    };
    fetch("/api/query/slide", options)
        .then((response) => {
            if (response.ok) {
                return response.blob();
            } else {
                return Promise.reject(response);
            }
        })
        .then((blob) => {
            // FIXME Consider using a library to protect against browser changes
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = url;
            a.download = question + ".pptx";

            document.body.appendChild(a);
            a.click();
            a.remove();
            onDownloadComplete();
        })
        .catch(() => {
            // TODO do something on error or unauthorized?
            onDownloadError();
        });
}
