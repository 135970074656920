export function removeHigherHierarchies(config, dimensions) {
    let dimensionsCopy = { ...dimensions };

    for (const h in config.hierarchy) {
        let remove = false;
        for (let i = config.hierarchy[h].length - 1; i >= 0; i--) {
            if (remove && config.hierarchy[h][i] in dimensionsCopy) {
                delete dimensionsCopy[config.hierarchy[h][i]];
            }
            if (config.hierarchy[h][i] in dimensionsCopy) {
                remove = true;
            }
        }
    }
    return dimensionsCopy;
}
