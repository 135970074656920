"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var ProcurementService = /** @class */ (function () {
    function ProcurementService() {
    }
    /**
     * List Projects
     * @param page
     * @param limit
     * @returns PaginatedResponse_ProcurementProject_ The paginated list of procurement projects.
     * @throws ApiError
     */
    ProcurementService.procurementListProjects = function (page, limit) {
        if (limit === void 0) { limit = 20; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/procurement',
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Create Project
     * @param requestBody
     * @returns ProcurementProject The created procurement project.
     * @throws ApiError
     */
    ProcurementService.procurementCreateProject = function (requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/procurement',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get Project
     * @param projectId
     * @param includePrioritize
     * @param includeSchedule
     * @returns ProcurementProject The procurement project with the given ID.
     * @throws ApiError
     */
    ProcurementService.procurementGetProject = function (projectId, includePrioritize, includeSchedule) {
        if (includePrioritize === void 0) { includePrioritize = false; }
        if (includeSchedule === void 0) { includeSchedule = false; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/procurement/{project_id}',
            path: {
                'project_id': projectId,
            },
            query: {
                'include_prioritize': includePrioritize,
                'include_schedule': includeSchedule,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Update Project
     * @param projectId
     * @param requestBody
     * @returns ProcurementProject The updated procurement project.
     * @throws ApiError
     */
    ProcurementService.procurementUpdateProject = function (projectId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/procurement/{project_id}',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Delete Project
     * @param projectId
     * @returns ProcurementProject The deleted procurement project.
     * @throws ApiError
     */
    ProcurementService.procurementDeleteProject = function (projectId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/procurement/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Validate Project
     * @param projectId
     * @returns ProcurementProjectStatus The project validation.
     * @throws ApiError
     */
    ProcurementService.procurementValidateProject = function (projectId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/procurement/{project_id}/validate',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Prioritize Initiatives
     * @param projectId
     * @param requestBody
     * @param dryRun
     * @returns ProcurementProject The updated procurement project.
     * @throws ApiError
     */
    ProcurementService.procurementPrioritizeInitiatives = function (projectId, requestBody, dryRun) {
        if (dryRun === void 0) { dryRun = false; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/procurement/{project_id}/prioritize',
            path: {
                'project_id': projectId,
            },
            query: {
                'dry_run': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Schedule Initiatives
     * @param projectId
     * @param requestBody
     * @param implementationStartDate
     * @param dryRun
     * @returns ProcurementProject The updated procurement project.
     * @throws ApiError
     */
    ProcurementService.procurementScheduleInitiatives = function (projectId, requestBody, implementationStartDate, dryRun) {
        if (dryRun === void 0) { dryRun = false; }
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/procurement/{project_id}/schedule',
            path: {
                'project_id': projectId,
            },
            query: {
                'implementation_start_date': implementationStartDate,
                'dry_run': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Get Project Initiative
     * @param projectId
     * @param initiativeId
     * @returns ProcurementInitiative The procurement project initiative.
     * @throws ApiError
     */
    ProcurementService.procurementGetProjectInitiative = function (projectId, initiativeId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/procurement/{project_id}/initiative/{initiative_id}',
            path: {
                'project_id': projectId,
                'initiative_id': initiativeId,
            },
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Update Project Initiative
     * @param projectId
     * @param initiativeId
     * @param requestBody
     * @returns ProcurementInitiative The updated procurement project initiative.
     * @throws ApiError
     */
    ProcurementService.procurementUpdateProjectInitiative = function (projectId, initiativeId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/procurement/{project_id}/initiative/{initiative_id}',
            path: {
                'project_id': projectId,
                'initiative_id': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Bulk Update Project Initiative
     * @param projectId
     * @param requestBody
     * @returns ProcurementInitiative The updated procurement project initiatives.
     * @throws ApiError
     */
    ProcurementService.procurementBulkUpdateProjectInitiative = function (projectId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/procurement/{project_id}/initiative',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    /**
     * Preview Project Initiative
     * @param projectId
     * @param initiativeId
     * @param requestBody
     * @returns ProcurementInitiative The previewed procurement project initiative.
     * @throws ApiError
     */
    ProcurementService.procurementPreviewProjectInitiative = function (projectId, initiativeId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/procurement/{project_id}/initiative/{initiative_id}/preview',
            path: {
                'project_id': projectId,
                'initiative_id': initiativeId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: "Validation Error",
            },
        });
    };
    return ProcurementService;
}());
exports.ProcurementService = ProcurementService;
