import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { formatBreakEven, formatCompactAmount, formatIrr } from "../../../../util/formatter";
import PropTypes from "prop-types";
import DiscardDialog from "../../../../components/DiscardDialog";
import { compareSet } from "../../../../util/util";
import { AppContext } from "../../../../AppRouter";

const InitiativesDialog = ({ open, initiatives, onClose, onSave }) => {
    const { config } = useContext(AppContext);

    const [originalIdentifiedInitiatives, setOriginalIdentifiedInitiatives] = useState(new Set());
    const [tempIdentifiedInitiatives, setTempIdentifiedInitiatives] = useState(new Set());

    const [showExitDialog, setShowExitDialog] = useState(false);

    useEffect(() => {
        if (initiatives) {
            const prioritized = initiatives
                .filter(initiative => initiative.identified)
                .map(initiative => initiative.id);

            setOriginalIdentifiedInitiatives(new Set(prioritized));
            setTempIdentifiedInitiatives(new Set(prioritized));
        } else {
            setOriginalIdentifiedInitiatives(new Set());
            setTempIdentifiedInitiatives(new Set());
        }
    }, [initiatives]);

    if (!initiatives) {
        return null;
    }

    const columns = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            sortable: true,
        },
        {
            field: "name",
            headerName: config.i18n.procurement.identify.name_column,
            flex: 5, // 5x wider than the other columns = half
        },
        {
            field: "run_rate",
            headerName: config.i18n.procurement.identify.value_proven.run_rate,
            type: "number",
            flex: 1,
            valueGetter: params => params.row.consolidated.run_rate,
            valueFormatter: params => formatCompactAmount(params.value, config.locale),
        },
        {
            field: "npv",
            headerName: config.i18n.procurement.identify.npv,
            type: "number",
            flex: 1,
            valueFormatter: params => formatCompactAmount(params.value, config.locale),
        },
        {
            field: "irr",
            headerName: config.i18n.procurement.identify.irr,
            type: "number",
            flex: 1,
            valueFormatter: params => formatIrr(params.value, config.locale, config.i18n.infinity),
        },
        {
            field: "break_even",
            headerName: config.i18n.procurement.identify.break_even,
            type: "number",
            flex: 1,
            valueFormatter: params => formatBreakEven(params.value, config.locale, config.i18n),
        },
        {
            field: "simplicity",
            headerName: config.i18n.procurement.simplicity.label,
            flex: 1,
            valueFormatter: params => config.i18n.procurement.simplicity.values[params?.value],
        },
    ];

    return (
        <>
            <DiscardDialog
                open={showExitDialog}
                onDiscard={() => {
                    setShowExitDialog(false);
                    onClose();
                    setTempIdentifiedInitiatives(originalIdentifiedInitiatives);
                }}
                onCancel={() => setShowExitDialog(false)}
            />
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>{config.i18n.procurement.prioritize.initiatives_dialog}</DialogTitle>
                <DialogContent>
                    <DataGrid
                        loading={!initiatives}
                        rows={initiatives}
                        columns={columns}
                        rowHeight={38}
                        disableColumnSelector
                        checkboxSelection
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "npv", sort: "desc" }],
                            },
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                        pageSizeOptions={[10]}
                        onRowSelectionModelChange={(model) => {
                            setTempIdentifiedInitiatives(new Set(model));
                        }}
                        rowSelectionModel={[...tempIdentifiedInitiatives]}
                        slots={{ toolbar: GridToolbarQuickFilter }}
                        sx={{
                            "maxHeight": "490px",
                            ".MuiDataGrid-virtualScroller": {
                                overflowX: "hidden",
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        if (compareSet(originalIdentifiedInitiatives, tempIdentifiedInitiatives)) {
                            onClose();
                        } else {
                            setShowExitDialog(true);
                        }
                    }}
                    >
                        {config.i18n.button.cancel}
                    </Button>
                    <Button
                        onClick={() => {
                            onSave(tempIdentifiedInitiatives);
                            onClose();
                        }}
                    >
                        {config.i18n.button.ok}
                    </Button>
                </DialogActions>

            </Dialog>
        </>

    );
};

InitiativesDialog.propTypes = {
    open: PropTypes.bool,
    initiatives: PropTypes.array,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
};

InitiativesDialog.defaultProps = {
    onClose: () => {},
    onSave: () => {},
};

export default InitiativesDialog;
