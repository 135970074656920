"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigurationService = void 0;
var OpenAPI_1 = require("../core/OpenAPI");
var request_1 = require("../core/request");
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService() {
    }
    /**
     * Get Auth
     * @returns AuthSettings Successful Response
     * @throws ApiError
     */
    ConfigurationService.configurationGetAuth = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/config/auth',
        });
    };
    /**
     * Get
     * @returns any Successful Response
     * @throws ApiError
     */
    ConfigurationService.configurationGet = function () {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/config',
        });
    };
    return ConfigurationService;
}());
exports.ConfigurationService = ConfigurationService;
