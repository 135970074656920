import React, { useContext, useEffect } from "react";
import MuiMarkdown from "mui-markdown";

import AdvisorContainer from "../../layout/AdvisorContainer";

import askMarkdownPath from "./Ask.md";
import reportMarkdownPath from "./Report.md";
import forecastMarkdownPath from "./Forecast.md";
import optimizeMarkdownPath from "./Optimize.md";

import { AppContext } from "../../AppRouter";
import PropTypes from "prop-types";

export const UserGuideAsk = () => {
    const { config } = useContext(AppContext);

    return <UserGuide title={config.i18n.page.ask_me} markdownPath={askMarkdownPath} />;
};

export const UserGuideReport = () => {
    const { config } = useContext(AppContext);

    return <UserGuide title={config.i18n.page.report} markdownPath={reportMarkdownPath} />;
};

export const UserGuideForecast = () => {
    const { config } = useContext(AppContext);

    return <UserGuide title={config.i18n.page.scenario} markdownPath={forecastMarkdownPath} />;
};

export const UserGuideOptimize = () => {
    const { config } = useContext(AppContext);

    return <UserGuide title={config.i18n.page.optimize} markdownPath={optimizeMarkdownPath} />;
};

const UserGuide = ({ title, markdownPath }) => {
    const { config } = useContext(AppContext);
    const [markdown, setMarkdown] = React.useState("");

    // we could use webpack to load the resource instead
    useEffect(() => {
        fetch(markdownPath)
            .then(response => response.text())
            .then(setMarkdown);
    }, []);

    return (
        <AdvisorContainer title={`${config.i18n.page.user_guide} - ${title}`}>
            <MuiMarkdown>{markdown}</MuiMarkdown>
        </AdvisorContainer>
    );
};

UserGuide.propTypes = {
    title: PropTypes.string.isRequired,
    markdownPath: PropTypes.object.isRequired,
};
