import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

// TODO re-enable React.StrictMode (it breaks the Okta widget)
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
//
// TL;DR
// Strict Mode has gotten stricter in React 18, and not all your components may
// be resilient to the new checks it adds in development mode. If removing
// Strict Mode fixes your app, you can remove it during the upgrade, and then
// add it back (either at the top or for a part of the tree) after you fix the
// issues that it’s pointing out.
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
